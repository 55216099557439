import * as yup from "yup";

export const UserCreation = yup.object().shape({
    name: yup.string().required('Name is required.'),
    email: yup.string().required('Email is required.').email('Invalid email format.'),
    company: yup.string().required('Please select a company.'),
    password: yup.string()
        .required('Password is required.')
        .min(8, 'Password must be at least 8 characters long.')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Password must contain at least one letter, one number, and one symbol.'
        ),
    confirmpassword: yup.string()
        .required('Confirm Password is required.')
        .test('password-match', 'Passwords must match.', function (value) {
            return value === this.resolve(yup.ref('password'));
        }),
});

export const FormTemplate = yup.object().shape({
    title: yup.string().required('Title is required.'),
});

export const SenquipValidation = yup.object().shape({
    serial: yup.string().required('Serial is required.').length(9, 'Serial must be exactly 9 characters long.'),
});

export const LoraWanGatewayValidation = yup.object().shape({
    description: yup.string().required('Seiral Number/Description is required.'),
    name: yup.string().required('Name is required.'),
    devEUI: yup.string().required('DevEUI is required.'),
    region: yup.string().required('Region is required.'),
});

const hexString = (length: number) => yup.string()
    .matches(new RegExp(`^[0-9A-Fa-f]{${length}}$`), `Must be a ${length}-digit hexadecimal value.`);

export const LoraWanDeviceValidation = yup.object().shape({
    description: yup.string().required('Serial Number/Description is required.'),
    name: yup.string().required('Name is required.'),
    devEUI: hexString(16).required('DevEUI is required.'),
    loraConfig: yup.string().required('Activation Type is required.'),
    'OtaaV1_1-AppKey': yup.string().when('loraConfig', {
        is: 'OtaaV1_1',
        then: () => hexString(32).required('App Key is required')
    }),
    'OtaaV1_1-NwkKey': yup.string().when('loraConfig', {
        is: 'OtaaV1_1',
        then: () => hexString(32).required('Network Key is required')
    }),
    'OtaaV1_1-JoinEui': yup.string().when('loraConfig', {
        is: 'OtaaV1_1',
        then: () => hexString(16).required('Join EUI is required')
    }),
    'OtaaV1_0_x-AppKey': yup.string().when('loraConfig', {
        is: 'OtaaV1_0_x',
        then: () => hexString(32).required('App Key is required')
    }),
    'OtaaV1_0_x-AppEui': yup.string().when('loraConfig', {
        is: 'OtaaV1_0_x',
        then: (schema) => schema.test({
            name: 'either-appEui-or-joinEui',
            test: function(value) {
                const joinEui = this.parent['OtaaV1_0_x-JoinEui'];
                if (!value && !joinEui) {
                    return this.createError({
                        message: 'Either App EUI or Join EUI must be provided'
                    });
                }
                return value ? hexString(16).isValidSync(value) : true;
            }
        })
    }),
    'OtaaV1_0_x-JoinEui': yup.string().when('loraConfig', {
        is: 'OtaaV1_0_x',
        then: (schema) => schema.test({
            name: 'either-appEui-or-joinEui',
            test: function(value) {
                const appEui = this.parent['OtaaV1_0_x-AppEui'];
                if (!value && !appEui) {
                    return this.createError({
                        message: 'Either App EUI or Join EUI must be provided'
                    });
                }
                return value ? hexString(16).isValidSync(value) : true;
            }
        })
    }),
    'AbpV1_1-DevAddr': yup.string().when('loraConfig', {
        is: 'AbpV1_1',
        then: () => hexString(8).required('Device Address is required')
    }),
    'AbpV1_1-FNwkSIntKey': yup.string().when('loraConfig', {
        is: 'AbpV1_1',
        then: () => hexString(32).required('FNwkSIntKey is required')
    }),
    'AbpV1_1-SNwkSIntKey': yup.string().when('loraConfig', {
        is: 'AbpV1_1',
        then: () => hexString(32).required('SNwkSIntKey is required')
    }),
    'AbpV1_1-NwkSEncKey': yup.string().when('loraConfig', {
        is: 'AbpV1_1',
        then: () => hexString(32).required('NwkSEncKey is required')
    }),
    'AbpV1_1-AppSKey': yup.string().when('loraConfig', {
        is: 'AbpV1_1',
        then: () => hexString(32).required('App SKey is required')
    }),
    'AbpV1_0_x-DevAddr': yup.string().when('loraConfig', {
        is: 'AbpV1_0_x',
        then: () => hexString(8).required('Device Address is required')
    }),
    'AbpV1_0_x-NwkSKey': yup.string().when('loraConfig', {
        is: 'AbpV1_0_x',
        then: () => hexString(32).required('Network Session Key is required')
    }),
    'AbpV1_0_x-AppSKey': yup.string().when('loraConfig', {
        is: 'AbpV1_0_x',
        then: () => hexString(32).required('App SKey is required')
    })
});


export const LoraWanEditDeviceValidation = yup.object().shape({
    description: yup.string().required('Serial Number/Description is required.'),
    name: yup.string().required('Name is required.'),
    devEUI: hexString(16).required('DevEUI is required.'),
    loraConfig: yup.string().required('Activation Type is required.'),
});

export const SectionDataValidation = yup.object().shape({
    title: yup.string().required('Title is required.'),
    deviceId: yup.string().required('DeviceId is required.'),
});
