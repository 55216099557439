import React, {useState} from 'react';
import './Sidebar.css';
import {useSelector} from 'react-redux';
import {IRedux} from '../../Interfaces/redux';
import {Button, Card, Col,Row} from 'react-bootstrap';
import {BindingHooks} from "../../Server/Binding/bidings/BindingHook";
import {Panel, PanelBody} from "../panel/panel";
import {HiOutlineDocumentAdd, HiOutlineDocumentReport} from "react-icons/hi";
import Template from "../Reports/Template";
import {CiEdit} from "react-icons/ci";
import {ApiInterfaces} from "../../Interfaces";
import { useNavigate } from 'react-router-dom';
import {  FiTrash } from 'react-icons/fi';
import { ApiHelper } from '../../Server/Events/ApiOperations';
import ConfirmModal from '../Utils/ConfirmModal';
import useModalCommunication from '../Utils/Hooks/modal';


const ReportBuilder: React.FC = () => {
    const {user} = useSelector((state: IRedux.MapState) => state.auth)
    const {data:devices} = BindingHooks.Device(user.companyId, 'DevList', true)
    const {data} = BindingHooks.Template(user.companyId, 'Templates', true)
    const { isOpen, openModal:open, closeModal } = useModalCommunication('TemplateModal');
    const [template, setTemplate] = useState<Partial<ApiInterfaces.Template>>({});
    
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState<Partial<ApiInterfaces.Template> | undefined>(undefined);

    const navigate = useNavigate();

    const openModal = (template: Partial<ApiInterfaces.Template>) => {
        setTemplate(template)
        open()
    }
    const viewTemplate = (templateId: string) => {
        navigate(`/template/${templateId}`);
    };


    const handleDeleteTemplate = async () => {
        if (templateToDelete) {
            await ApiHelper.op("template.delete", { id: templateToDelete.id || "", companyId: user.companyId }, user.companyId);
            setShowDeleteModal(false);
            setTemplateToDelete(undefined);
        }
    };

    const confirmDelete = (template: Partial<ApiInterfaces.Template>) => {
        setTemplateToDelete(template);
        setShowDeleteModal(true);
    };


    return (
        devices ? <>
            <Template show={isOpen} handleClose={() => {
                closeModal()
                setTemplate({})
            }} key={template.id || "new"} template={template} devices={devices} />
            <Row className="justify-content-center">
                <Col xs={12} md={8} xl={5}>
                    <Panel theme={'inverse'} reload={false} style={{marginBottom: '0rem'}}>
                        <PanelBody className="p-6">
                            <h3 className="text-center"> Report Templates </h3>
                        </PanelBody>
                    </Panel>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12} md={8} xl={5}>
                    <Panel theme={'inverse'} reload={false} style={{marginBottom: '0rem'}}>
                        <PanelBody className="p-6">
                            <Button variant={'outline-info'} className="d-flex align-items-center mb-3" onClick={() => open()}>
                                <HiOutlineDocumentAdd size={23} className="me-2" /> New Template
                            </Button>
                            <hr />
                            <div className="mt-3">
                                {data?.map((template, index) => (
                                    <Card key={index} className="mb-3 shadow border rounded" style={{ borderColor: '#6c757d' }}>
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <span className="d-flex align-items-center ps-3">
                                                <HiOutlineDocumentReport size={20} className="me-2 text-primary" />
                                                <strong>{template.title}</strong>
                                            </span>
                                            <div className="d-flex">
                                                <Button variant="outline-success" className="me-2 d-flex align-items-center" onClick={() => viewTemplate(template.id)}>
                                                    <HiOutlineDocumentReport size={20} className="me-1" />
                                                    <span className="d-none d-md-inline-block">Open</span>
                                                </Button>
                                                <Button variant="outline-warning" className="me-2 d-flex align-items-center" onClick={() => openModal(template)}>
                                                    <CiEdit size={20} className="me-1" />
                                                    <span className="d-none d-md-inline-block">Edit</span>
                                                </Button>
                                                <Button variant="outline-danger" className="d-flex align-items-center"onClick={() => confirmDelete(template)}>
                                                    <FiTrash size={20} className="me-0" />
                                                    <span className="d-none d-md-inline-block"></span>
                                                </Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </PanelBody>
                    </Panel>
                </Col>
            </Row>
            <ConfirmModal
                    message="Are you sure you want to delete this template?"
                    action={handleDeleteTemplate}
                    show={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false);
                        setTemplateToDelete(undefined);
                    }}
                />
        </> : <></>
    );
};

export default ReportBuilder;