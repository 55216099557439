import React from 'react';
import { Modal, Button, Form, Alert, Card } from 'react-bootstrap';
import { DynamicForm } from "../../Utils/Forms/Form";
import { Input, InputValue, Section } from "../../Utils/Forms/Interface";
import { useRef, useState } from 'react';
import { FormAction } from "../../Utils/Forms/Interface";
import { ApiHelper } from '../../../Server/Events/ApiOperations';
import { useSelector } from 'react-redux';
import { FiTrash, FiX, FiSave } from 'react-icons/fi';
import { FaQrcode } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import { ApiInterfaces } from '../../../Interfaces';
import { IRedux } from '../../../Interfaces/redux';
import ReusableModal from '../../Utils/ReusableModal';
import { LoraWanDeviceValidation, LoraWanEditDeviceValidation } from '../../Utils/Forms/Validation';
import handleQR_Print from '../../Utils/QR_Print';
import { LoraWanDevices } from './types';
import { Typeahead } from 'react-bootstrap-typeahead';


interface WirelessDeviceModalProps {
    show: boolean;
    handleClose: () => void;
    device: Partial<ApiInterfaces.WirelessDevice>;
    companies: ApiInterfaces.Company[];
}


const WirelessDeviceModal: React.FC<WirelessDeviceModalProps> = ({ show, handleClose, device, companies }) => {
    const formRef = useRef<FormAction<Partial<ApiInterfaces.WirelessDevice>> | undefined>(undefined);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const { user } = useSelector((state: IRedux.MapState) => state.auth);
    const [selectedDeviceType, setSelectedDeviceType] = useState<string[]>([]);

    const [qrValue, setQrValue] = useState<string>('');

    const qrRef = useRef<HTMLDivElement>(null);

    const deviceTypes = Object.keys(LoraWanDevices.device).map(key => ({
        id: key,
        label: LoraWanDevices.device[key].id
    }));

    const ActivationTypeOptions = { ["OtaaV1_1"]: "OTAA V1_1", ["OtaaV1_0_x"]: "OTAA V1_0_x", ["AbpV1_1"]: "ABP V1_1", ["AbpV1_0_x"]: "ABP V1_0_x" }

    function addSummaryRow(type: 'OtaaV1_1' | 'OtaaV1_0_x' | 'AbpV1_1' | 'AbpV1_0_x') {
        const f = formRef.current;
        if (!f) return;
        f.setHidden('activationSection', false);
        // Define text input fields based on the activation type
        switch (type) {
            case 'OtaaV1_1':
                f.setHidden('OtaaV1_1Row', false);
                f.setHidden('OtaaV1_0_xRow', true);
                f.setHidden('AbpV1_1Row', true);
                f.setHidden('AbpV1_0_xRow', true);
                break;
            case 'OtaaV1_0_x':
                f.setHidden('OtaaV1_0_xRow', false);
                f.setHidden('OtaaV1_1Row', true);
                f.setHidden('AbpV1_1Row', true);
                f.setHidden('AbpV1_0_xRow', true);
                break;
            case 'AbpV1_1':
                f.setHidden('AbpV1_1Row', false);
                f.setHidden('OtaaV1_1Row', true);
                f.setHidden('OtaaV1_0_xRow', true);
                f.setHidden('AbpV1_0_xRow', true);
                break;
            case 'AbpV1_0_x':
                f.setHidden('AbpV1_0_xRow', false);
                f.setHidden('OtaaV1_1Row', true);
                f.setHidden('OtaaV1_0_xRow', true);
                f.setHidden('AbpV1_1Row', true);
                break;
        }
    }


    const LoraWanDeviceForm: Section = {
        type: "section",
        title: "LoRaWAN Device",
        id: "main",
        items: [
            {
                type: "row",
                id: "mainRow",
                items: [
                    { width: 12, type: "text", id: "lora_uuid", label: "Device AWS IoT uuid", value: "[lora_uuid]", disabled: device.id ? true : false, hidden: device.id ? false : true },
                    { width: 12, type: "text", className: 'text-uppercase', id: "description", label: "Device Serial Number", value: "[description]" },
                    { width: 12, type: "text", id: "name", label: "Lora Device Name", value: "[name]" },
                    { width: 12, type: "text", className: 'text-uppercase', id: "devEUI", label: "Lora Device DevEUI", value: "[devEUI]", disabled: device.id ? true : false, muted: "The 16-digit hexadecimal DevEUI value found on your wireless device." },
                    { width: 12, id: "deviceId", type: "text", label: "Device ID", value: "[deviceId]", disabled: true, hidden: !device.deviceId ? true : false },
                    { width: 12, type: "dropdown", id: "loraConfig", label: "Activation Type", value: "[loraConfig]", hidden: false, disabled: device.id ? true : false, options: ActivationTypeOptions },
                    { width: 12, type: "text", id: "device_type", label: "Sensor Type", value: "[device_type]", hidden: device.device_type ? false : true, disabled: true },

                ],
            },
        ],
    };

    const ActivationSection: Section = {
        type: "section",
        title: "LoRaWAN Activation",
        id: "activationSection",
        hidden: true,
        items: [
            {
                type: "row",
                id: "OtaaV1_1Row",
                hidden: true,
                items: [
                    { type: "text", id: `OtaaV1_1-AppKey`, className: 'text-uppercase', label: "App Key", value: "[AppKey]", width: 12, muted: "The 32-digit hexadecimal AppKey value that your wireless device vendor provided." },
                    { type: "text", id: `OtaaV1_1-NwkKey`, className: 'text-uppercase', label: "Network Key", value: "[NwkKey]", width: 12, muted: "The 32-digit hexadecimal NwkKey value that your wireless device vendor provided." },
                    { type: "text", id: `OtaaV1_1-JoinEui`, className: 'text-uppercase', label: "Join EUI", value: "[JoinEui]", width: 12, muted: "The 16-digit hexadecimal JoinEUI value that your wireless device vendor provided." }

                    // Add more form fields as needed based on your Senquip interface
                ],
            },
            {
                type: "row",
                id: "OtaaV1_0_xRow",
                hidden: true,
                items: [
                    { type: "text", id: `OtaaV1_0_x-AppKey`, className: 'text-uppercase', label: "App Key", value: "[AppKey]", width: 12, muted: "This is the App Key for the device. It is used to encrypt and decrypt the data sent to the device." },
                    { type: "text", id: `OtaaV1_0_x-AppEui`, className: 'text-uppercase', label: "App EUI", value: "[AppEui]", width: 12, muted: "The 16-digit hexadecimal AppEUI/JoinEUI that your wireless device vendor provided. For MAC version 1.0.4, please use JoinEUI. Otherwise please use AppEUI." },
                    { type: "text", id: `OtaaV1_0_x-JoinEui`, className: 'text-uppercase', label: "Join EUI", value: "[JoinEui]", width: 12, muted: "The 16-digit hexadecimal AppEUI/JoinEUI that your wireless device vendor provided. For MAC version 1.0.4, please use JoinEUI. Otherwise please use AppEUI." }
                ],
            },
            {
                type: "row",
                id: "AbpV1_1Row",
                hidden: true,
                items: [
                    { type: "text", id: `AbpV1_1-DevAddr`, className: 'text-uppercase', label: "Device Address", value: "[DevAddr]", width: 12, muted: 'The 8-digit hexadecimal DevAddr that your wireless device vendor provided.' },
                    { type: "text", id: `AbpV1_1-FNwkSIntKey`, className: 'text-uppercase', label: "FNwkSIntKey", value: "[FNwkSIntKey]", width: 12, muted: 'The 32-digit alphanumeric NwkSEncKey that your wireless device vendor provided..' },
                    { type: "text", id: `AbpV1_1-SNwkSIntKey`, className: 'text-uppercase', label: "SNwkSIntKey", value: "[SNwkSIntKey]", width: 12, muted: 'The 32-digit hexadecimal SNwkSIntKey value that your wireless device vendor provided.' },
                    { type: "text", id: `AbpV1_1-NwkSEncKey`, className: 'text-uppercase', label: "NwkSEncKey", value: "[NwkSEncKey]", width: 12, muted: 'The 32-digit alphanumeric NwkSEncKey that your wireless device vendor provided.' },
                    { type: "text", id: `AbpV1_1-AppSKey`, className: 'text-uppercase', label: "App SKey", value: "[AppSKey]", width: 12, muted: 'The 32-digit hexadecimal AppSKey value that your wireless device vendor provided.' }
                ],
            },
            {
                type: "row",
                id: "AbpV1_0_xRow",
                hidden: true,
                items: [
                    { type: "text", id: `AbpV1_0_x-DevAddr`, className: 'text-uppercase', label: "Device Address", value: "[DevAddr]", width: 12, muted: 'The 8-digit hexadecimal DevAddr that your wireless device vendor provided.' },
                    { type: "text", id: `AbpV1_0_x-NwkSKey`, className: 'text-uppercase', label: "Network Session Key", value: "[NwkSKey]", width: 12, muted: 'The 32-digit hexadecimal NwkSKey value that your wireless device vendor provided.' },
                    { type: "text", id: `AbpV1_0_x-AppSKey`, className: 'text-uppercase', label: "App SKey", value: "[AppSKey]", width: 12, muted: 'The 32-digit hexadecimal AppSKey value that your wireless device vendor provided.' }
                ],

            }
        ],
    };

    const handleDelete = async () => {
        if (!device.id) return;
        await ApiHelper.op("lorawanDevice.delete", { id: device.id }, user.managementsId || '')
        handleClose()
    }

    const handleSave = async () => {
        const f = formRef.current;
        if (!f) return;
        const valid = await f.getYupValidation(device.id ? LoraWanEditDeviceValidation : LoraWanDeviceValidation)
        const data = f.getFormData();
        if (data && valid.isValid) {
            try {

                const upload = {
                    ...data,
                    timestamp: device.timestamp || new Date(),
                } as ApiInterfaces.WirelessDevice
                await ApiHelper.op(data.id ? "lorawanDevice.edit" : "lorawanDevice.create", upload, user.managementsId || '')
                handleClose()


            } catch (error) {
                console.error('Failed to save Senquip data:', error);
                // Optionally add error handling UI here
            }
        }
    };

    return (
        <>
            <ReusableModal
                show={show}
                onHide={handleClose}
                onSave={handleSave}
                title={device.id ? 'Edit Device' : 'Add Device'}
                deleteMessage={device.id ? "Are you sure you want to delete this Device? Users won't be able to use it anymore, this action is irreversible and cannot be undone. Resource will be deleted from the system including AWS IoT." : undefined}
                deleteAction={device.id ? handleDelete : undefined}
                hasChange={hasChanges}
            >
                <DynamicForm<Partial<ApiInterfaces.WirelessDevice>>
                    actionTriggered={(id, action, form) => {
                        formRef.current = form;
                        return true;
                    }}
                    valueChanged={(id, value, valid, form) => {
                        if (hasChanges === false) setHasChanges(true);
                        if (id === 'loraConfig') {
                            addSummaryRow(value as 'OtaaV1_1' | 'OtaaV1_0_x' | 'AbpV1_1' | 'AbpV1_0_x');
                        }
                        return true;
                    }}
                    id="Gateway"
                    inputData={device}
                    content={[LoraWanDeviceForm, ActivationSection]}
                />
                {!device.deviceId && device.id && (
                    <>
                        <Alert variant="info">
                            <p>Device Available</p>
                            <p>Awaiting Client Adoption</p>
                        </Alert>
                    </>
                )}

                {device.id && (
                    <>
                        <hr />

                        {!device.deviceId &&
                            <Form.Group className="w-100">
                                <Form.Label className={selectedDeviceType.length > 0 ? "text-success" : "text-danger"}>
                                    Device Type {selectedDeviceType.length > 0 ? "✓" : "*"}
                                </Form.Label>
                                <div className="d-flex gap-2 w-100">
                                    <Typeahead
                                        id="device-type-select"
                                        className="w-75"
                                        options={deviceTypes}
                                        placeholder="Select device type..."
                                        onChange={(selected: any[]) =>
                                            setSelectedDeviceType(selected.map((s) => s.id))
                                        }
                                        selected={selectedDeviceType.map((id) => {
                                            const deviceType = deviceTypes.find((dt) => dt.id === id);
                                            return deviceType
                                                ? { id: deviceType.id, label: deviceType.label }
                                                : { id: id, label: id };
                                        })}
                                        isValid={selectedDeviceType.length > 0}
                                        isInvalid={selectedDeviceType.length === 0}
                                    />
                                    <Button
                                        variant="outline-info"
                                        className="d-flex align-items-center gap-2 w-25"
                                        disabled={selectedDeviceType.length === 0}
                                        onClick={() => setQrValue(`se:${device.devEUI};pi:${device.pin};t:${selectedDeviceType.join(',')}`)}
                                    >
                                        <FaQrcode size={20} />
                                        <span>QR</span>
                                    </Button>
                                </div>
                                {selectedDeviceType.length === 0 && (
                                    <Form.Text className="text-danger">
                                        Please select a device type
                                    </Form.Text>
                                )}
                            </Form.Group>}
                        {qrValue && (
                            <>
                                <hr />
                                <div ref={qrRef} className="d-flex justify-content-center align-items-center mt-3">
                                    <Card className="p-4 qr-card shadow-sm">
                                        <QRCodeSVG value={qrValue} size={256} />
                                        <h5 className="mt-3">SierraNet LoraWAN Device QR Code</h5>
                                        <p className="text-muted">Scan to Connect Your Device.</p>
                                    </Card>
                                </div>
                                <hr />
                                <Button
                                    variant="outline-warning"
                                    className="mt-2 w-100"
                                    onClick={() => handleQR_Print(qrRef)}
                                >
                                    Print QR Code
                                </Button>
                            </>
                        )}

                    </>
                )}
            </ReusableModal>
        </>
    );
};

export default WirelessDeviceModal;
