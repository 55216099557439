// Sidebar.tsx
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Button, Nav } from 'react-bootstrap';
import './Sidebar.css';
import { IRedux } from '../../Interfaces/redux';
import { store } from '../../store';
import { logout } from '../../redux/actions/authAction';
import { ApiInterfaces } from '../../Interfaces';
import CompanySelector from '../Managment/CompanyDropdown';
import APIManager from '../../Server/Api/APIInterface';
import { Link } from 'react-router-dom';
import { ReduxActions } from '../../redux/actions';
import { BindingSet } from '../../Server/Binding/Binding';
import { Bindings } from '../../Server/Binding/bidings';
import { useSelector } from "react-redux";
import { MdDashboard, MdManageAccounts, MdBusiness, MdMap, MdSettings, MdDescription, MdShowChart, MdInfo, MdContactPhone, MdLogout, MdDeviceHub } from "react-icons/md";
import { TbReplace } from "react-icons/tb";
import { PiBarn, PiUserCircle } from "react-icons/pi";

interface SidebarProps {
    show: boolean;
    closeSidebar: () => void;
}

const bindings: BindingSet = new BindingSet({});

const Sidebar: React.FC<SidebarProps> = ({ show, closeSidebar }) => {
    const sideMenu = useRef<HTMLDivElement>(null);
    const [display, setDisplay] = useState<boolean>(false);
    const [managment, setManagment] = useState<ApiInterfaces.Managements | undefined>(undefined);
    const [company, setCompany] = useState<ApiInterfaces.Company | undefined>(undefined);
    const { user, accessToken } = useSelector((state: IRedux.MapState) => state.auth);

    const signOut = useCallback(async () => {
        const state = store.getState().auth as IRedux.IAuth;
        closeSidebar();
        ReduxActions.ShowLoader();
        try {
            await APIManager.snRequest('user.token.refresh.delete', { refreshToken: state.refreshToken });
        } catch (error) {
            console.error(error);
        }
        store.dispatch(logout());
        APIManager.logout();
    }, [closeSidebar]);

    const toggleCompanyList = useCallback(() => {
        setDisplay(prev => !prev);
    }, []);

    const handleLinkClick = useCallback(() => {
        if (window.innerWidth < 1200) closeSidebar();
    }, [closeSidebar]);

    useEffect(() => {
        bindings.bind();

        const fetchCompanyInfo = async () => {
            const res = await APIManager.ApiRequest('company.only.get', { id: user.companyId, accessToken });
            if (res.type === 'company.only.get') setCompany(res);
        };

        const CreateBinding = () => {
            const mgm = new Bindings.Management.Binding(accessToken, user.managementsId || "", (data) => {
                setManagment(Object.values(data)[0]);
            });
            const com = new Bindings.RefCompany.Binding(true, mgm, (data) => setCompany(data[user.companyId]));
            bindings.add('mgm', mgm);
            bindings.add('com', com);
        };

        if (user && user.managementsId) {
            CreateBinding();
        } else {
            fetchCompanyInfo();
        }

        return () => bindings.unbind();
    }, [accessToken, user]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (show && window.innerWidth < 1200 && sideMenu.current && !sideMenu.current.contains(event.target as Node)) {
                setDisplay(false);
                closeSidebar();
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [show, closeSidebar]);

    const bottomMenu = useMemo(() => (
        !display && (
            <div className='pb-4'>
                {user.managementsId && (
                    <>

                        {user.managementsId === process.env.REACT_APP_ROOT && (
                            <>
                                <Link className="d-flex align-items-center nav-link" to="senquip" onClick={handleLinkClick}>
                                    <MdDeviceHub color={'green'} size={22} /> &nbsp; Senquip Management
                                </Link>
                                <Link className="d-flex align-items-center nav-link" to="lorawan" onClick={handleLinkClick}>
                                    <MdBusiness color={'yellow'} size={22} /> &nbsp; LoraWAN Management
                                </Link>
                            </>
                        )}
                        <hr />
                        <Link className="d-flex align-items-center nav-link" to={"usermanagment"} onClick={handleLinkClick}>
                            <MdManageAccounts color={'orange'} size={22} /> &nbsp; User Mgmt
                        </Link>
                        <Link className="d-flex align-items-center nav-link" to={"companies"} onClick={handleLinkClick}>
                            <MdBusiness color={'orange'} size={22} />&nbsp; Companies Settings
                        </Link>
                        <Link className="d-flex align-items-center nav-link" to={"mapview"} onClick={handleLinkClick}>
                            <MdMap color={'orange'} size={22} />&nbsp; Map View
                        </Link>

                    </>
                )}
                <hr />
                <div className="d-grid gap-2 p-5">
                    <Button variant="outline-danger" onClick={signOut}>
                        <MdLogout size={22} /> &nbsp; Sign Out
                    </Button>
                </div>
            </div>
        )
    ), [display, handleLinkClick, signOut, user.managementsId]);

    return (
        <div ref={sideMenu} className={`left-theme-panel theme-panel-lg ${show ? 'active' : ''}`}>
            <div className="theme-panel-content">
                {show && (
                    <Nav className="d-flex flex-column h-100 overflow-auto pt-5">
                        <div className="d-flex flex-column flex-grow-1">
                            {company && (
                                <>
                                    <hr />
                                    <div className="d-flex align-items-center">
                                        <div className='fw-bolder ms-3'>
                                            <PiBarn size={35} color={'#e3995f'} />
                                        </div>
                                        <p className='fw-bolder ms-3 mb-0 d-flex align-items-center' title={company.name_Cia}>
                                            {company.name_Cia.length > 20 ? `${company.name_Cia.slice(0, 20)}...` : company.name_Cia} &nbsp;
                                            {managment && (
                                                <Button variant='outline-secondary' className="rounded-circle p-0 d-flex align-items-center justify-content-center" style={{ width: '30px', height: '30px', border: 'none' }} onClick={toggleCompanyList}>
                                                    <TbReplace size={20} color={'#e3995f'} />
                                                </Button>
                                            )}
                                        </p>
                                    </div>
                                    <div className="d-flex ms-3 w-100">
                                        <CompanySelector companies={managment?.companies || []} show={display} toggle={toggleCompanyList} />
                                    </div>
                                </>
                            )}
                            {!display && (
                                <>
                                    <hr />
                                    <div className="d-flex align-items-center">
                                        <div className='fw-bolder ms-3'>
                                            <PiUserCircle size={35} color={'#e3995f'} />
                                        </div>
                                        <p className="fw-bolder ms-3 mb-0">{user.name}</p>
                                    </div>
                                    <hr />
                                    <Link className="d-flex align-items-center nav-link" to={""} onClick={handleLinkClick}>
                                        <MdDashboard color={'teal'} size={22} /> &nbsp; Dashboard
                                    </Link>
                                    <Link className="d-flex align-items-center nav-link" to="devices" onClick={handleLinkClick}>
                                        <MdSettings color={'teal'} size={22} /> &nbsp; Devices Settings
                                    </Link>
                                    <Link className="d-flex align-items-center nav-link" to="reportBuilder" onClick={handleLinkClick}>
                                        <MdDescription color={'#ca3251'} size={22} /> &nbsp; Report Templates
                                    </Link>
                                    <Link className="d-flex align-items-center nav-link" to="charts" onClick={handleLinkClick}>
                                        <MdShowChart size={22} /> &nbsp; Charts
                                    </Link>
                                    <Nav.Link className="d-flex align-items-center nav-link" href="https://sierratek.com.au/pages/contact" target="_blank">
                                        <MdContactPhone color={'red'} size={20} /> &nbsp; Contact Us
                                    </Nav.Link>
                                    {window.innerHeight < 800 ? bottomMenu : <hr />}
                                </>
                            )}
                        </div>
                        {window.innerHeight >= 800 && bottomMenu}
                    </Nav>
                )}
            </div>
        </div>
    );
};

export default Sidebar;