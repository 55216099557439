import React from 'react';
import { Modal, Button, Form, Alert, Card } from 'react-bootstrap';
import { DynamicForm } from "../../Utils/Forms/Form";
import { Section } from "../../Utils/Forms/Interface";
import { useRef, useState } from 'react';
import { FormAction } from "../../Utils/Forms/Interface";
import { ApiHelper } from '../../../Server/Events/ApiOperations';
import { useSelector } from 'react-redux';
import { FiTrash, FiX, FiSave } from 'react-icons/fi';
import { FaQrcode } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';
import { ApiInterfaces } from '../../../Interfaces';
import { IRedux } from '../../../Interfaces/redux';
import ConfirmModal from '../../Utils/ConfirmModal';
import { LoraWanGatewayValidation } from '../../Utils/Forms/Validation';
import { generateSetupZip } from './Zip';
import ReusableModal from '../../Utils/ReusableModal';


interface GatewayModalProps {
    show: boolean;
    handleClose: () => void;
    gateway: Partial<ApiInterfaces.LoRaWANGateway>;
    companies: ApiInterfaces.Company[];
}


const GatewayModal: React.FC<GatewayModalProps> = ({ show, handleClose, gateway, companies }) => {
    const formRef = useRef<FormAction<Partial<ApiInterfaces.LoRaWANGateway>> | undefined>(undefined);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const { user } = useSelector((state: IRedux.MapState) => state.auth);

    const [qrValue, setQrValue] = useState<string>('');
    gateway.region = 'AU915'

    const qrRef = useRef<HTMLDivElement>(null);

    const handlePrint = () => {
        if (qrRef.current) {
            const printContent = qrRef.current.innerHTML;
            const printWindow = window.open("", "_blank");
            if (printWindow) {
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>Print QR Code</title>
                            <style>
                                body {
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100vh;
                                    background-color: #fff;
                                }
                                .qr-card {
                                    display: inline-block;
                                    padding: 20px;
                                    border: 1px solid #ccc;
                                    border-radius: 10px;
                                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                                }
                            </style>
                        </head>
                        <body>${printContent}</body>
                    </html>
                `);
                printWindow.document.close();
                printWindow.print();
            }
        }
    };

    const LoraWanGatewayForm: Section = {
        type: "section",
        title: "LoRaWAN Gateway",
        id: "main",
        items: [
            {
                type: "row",
                id: "mainRow",
                items: [
                    { width: 12, type: "text", id: "lora_uuid", label: "Gateway AWS IoT uuid", value: "[lora_uuid]", disabled: gateway.id ? true : false, hidden: gateway.id ? false : true },
                    { width: 12, type: "text", className: 'text-uppercase', id: "description", label: "Gateway Serial Number", value: "[description]" },
                    { width: 12, type: "text", id: "name", label: "Gateway Name", value: "[name]" },
                    { width: 12, type: "text", className: 'text-uppercase', id: "devEUI", label: "Gateway DevEUI", value: "[devEUI]", disabled: gateway.id ? true : false },
                    { width: 12, type: "text", className: 'text-uppercase', id: "region", label: "Gateway Region", value: "[region]", disabled: gateway.id ? true : false },
                    { width: 12, type: "text", id: "companyId", label: "Adopted By CompanyId", value: "[companyId]", hidden: gateway.id ? false : true, disabled: true },
                    // Add more form fields as needed based on your Senquip interface
                ],
            },
        ],
    };

    const handleDelete = async () => {
        if (!gateway.id) return;
        await ApiHelper.op("lorawanGateway.delete", { id: gateway.id, lora_uuid: gateway.lora_uuid || '' }, user.managementsId || '')
        handleClose()
    }

    const handleSave = async () => {
        const f = formRef.current;
        if (!f) return;
        const valid = await f.getYupValidation(LoraWanGatewayValidation)
        const data = f.getFormData();
        if (data && valid.isValid) {
            try {

                const upload = {
                    ...data,
                    timestamp: gateway.timestamp || new Date(),
                } as ApiInterfaces.LoRaWANGateway
                const res = await ApiHelper.op(data.id ? "lorawanGateway.edit" : "lorawanGateway.create", upload, user.managementsId || '')

                if (res?.type === 'lorawanGateway.create') {
                    await generateSetupZip(res.gateway, data.description || '', data.name || '')
                }

                
            } catch (error) {
                console.error('Failed to save Senquip data:', error);
                // Optionally add error handling UI here
            } finally {
                handleClose()
            }
        }
    };

    return (
        <>
            <ReusableModal
                show={show}
                onHide={handleClose}
                onSave={handleSave}
                title={gateway.id ? 'Edit Gateway' : 'Add Gateway'}
                deleteMessage={gateway.id ? "Are you sure you want to delete this Gateway? Users won't be able to use it anymore, this action is irreversible and cannot be undone. Resource will be deleted from the system including AWS IoT." : undefined}
                deleteAction={gateway.id ? handleDelete : undefined}
            >
                <DynamicForm<Partial<ApiInterfaces.LoRaWANGateway>>
                    actionTriggered={(id, action, form) => {
                        formRef.current = form;
                        return true;
                    }}
                    valueChanged={(id, value, valid, form) => {
                        setHasChanges(true);
                        return true;
                    }}
                    id="Gateway"
                    inputData={gateway}
                    content={[LoraWanGatewayForm]}
                />
                {!gateway.companyId && gateway.id && (
                    <>
                        <Alert variant="info">
                            <p>Gateway Available</p>
                            <p>Awaiting Client Adoption</p>
                        </Alert>
                    </>
                )}
                {gateway.id && (
                    <>
                        <hr />

                        {!gateway.companyId &&
                            <Form.Group className="w-100">

                                <div className="d-flex gap-2 w-100">
                                    <Button
                                        variant="outline-info"
                                        className="d-flex align-items-center gap-2 w-25"
                                        onClick={() => setQrValue(`se:${gateway.lora_uuid};pi:${gateway.pin};t:loraWanGateway;m:loraWanGateway`)}
                                    >
                                        <FaQrcode size={20} />
                                        <span>QR</span>
                                    </Button>
                                </div>

                            </Form.Group>}
                        {qrValue && (
                            <>
                                <hr />
                                <div ref={qrRef} className="d-flex justify-content-center align-items-center mt-3">
                                    <Card className="p-4 qr-card shadow-sm">
                                        <QRCodeSVG value={qrValue} size={256} />
                                        <h5 className="mt-3">SierraNet Gateway QR Code</h5>
                                        <p className="text-muted">Scan to Connect Your Gateway.</p>
                                    </Card>
                                </div>
                                <hr />
                                <Button
                                    variant="outline-warning"
                                    className="mt-2 w-100"
                                    onClick={handlePrint}
                                >
                                    Print QR Code
                                </Button>
                            </>
                        )}

                    </>
                )}
            </ReusableModal>
        </>
    );
};

export default GatewayModal;
