import React, { useEffect, useState } from "react";
import UserModal from "./modal";
import { useSelector} from "react-redux";
import { IRedux } from "../../Interfaces/redux";
import { ApiInterfaces } from "../../Interfaces";
import APIManager from "../../Server/Api/APIInterface";
import MultiSelectDropdown, { MultiSelectOption } from "../Utils/DropdownSelector";
import { Button, Table } from "react-bootstrap";
import { HelperTime } from "../../Helper/Time";
import { HelperFunctions } from "../../Helper/functions";
import { ReduxActions } from "../../redux/actions";
import useModalCommunication from "../Utils/Hooks/modal";



const UserMangment = () => {
    const [users, setUsers] = useState<ApiInterfaces.User[] | undefined>(undefined);
    const [usersFilter, setUsersFilter] = useState<ApiInterfaces.User[] | undefined>(undefined);
    const [companies, setCompanies] = useState<ApiInterfaces.Company[] | undefined>(undefined);
    const [managment, setManagment] = useState<ApiInterfaces.Managements | undefined>(undefined);
    const { isOpen, openModal, closeModal } = useModalCommunication('UserModal');
    const [selected, setSelected] = useState<Partial<ApiInterfaces.User> | undefined>(undefined);
    const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>([]);
    const {user, accessToken} = useSelector((state: IRedux.MapState) => state.auth)

    // const bindings: BindingSet = new BindingSet({});
    // const instance = MqttManager.getInstance(props.mqttToken)

    useEffect(() => {
        fetchData()
    }, []);
    
    useEffect(() => {
        const selectedValues = selectedOptions.map((option) => option.value);
        if (users) {

            const filteredUsers = selectedOptions.length === 0 ? users : users.filter(
                (user) => user.managementsId && selectedValues.includes(user.managementsId) || selectedValues.includes(user.companyId)
            );
            setUsersFilter(filteredUsers);
        }
    }, [users, selectedOptions]);


    const fetchData = async () => {
        try {

            ReduxActions.ShowLoader()

            // Fetch Companies
            const companiesRes = await APIManager.ApiRequest('management.get', {
                accessToken: accessToken,
                id: user.managementsId || ''
            });

            if (companiesRes.type === 'management.get') {
                setCompanies(companiesRes.companies || []);
                setManagment(companiesRes)
            } else {
                ReduxActions.ShowError('Unable to load management resource')
                ReduxActions.HideLoader()

                return;
            }

            // Fetch Users
            const usersRes = await APIManager.ApiRequest('management.list.users', {
                accessToken: accessToken,
                id: user.managementsId || ''
            });

            if (usersRes.type === 'management.list.users') {
                setUsers(usersRes.data);
                setUsersFilter(usersRes.data)
            } else {
                ReduxActions.ShowError('Unable to retrieve users')
            }
        } catch (error) {
            ReduxActions.ShowError('Error fetching data')
        } finally {
            ReduxActions.HideLoader()
        }
    };

    const addOrEditUser = (user: ApiInterfaces.User) => {
        // Check if the user with the same ID already exists in the usersFilter array
        if (users) {
            const index = users.findIndex((u) => u.id === user.id);

            if (index !== -1) {
                // If user with the same ID exists, update the user in the array
                const updatedFilter = [...users];
                updatedFilter[index] = user;
                setUsers(updatedFilter);
            } else {
                // If user with the same ID doesn't exist, add the user to the array
                setUsers([...users, user]);
            }
        }
    };

    const deleteUser = (id: string) => {
        if (users) {
            const updatedFilter = users.filter((user) => user.id !== id);
            setUsers(updatedFilter)
        };
    };


    function handleSelectChange(newSelectedOptions: MultiSelectOption[]): void {
        setSelectedOptions(newSelectedOptions);
    }

    const showUserModal = (show: boolean, user: Partial<ApiInterfaces.User>) => {
        openModal()
        setSelected(user)
    }



    return (
        <div style={{ padding: "20px" }}>
            <UserModal handleClose={() => {
                setSelected(undefined)
                closeModal()
            }
            } show={isOpen} isuser={selected} companies={companies || []} onAddOrEditUser={addOrEditUser} onDeleteUser={deleteUser} />
            <h1 className="page-header">
                Users Management &nbsp;&nbsp;
                <small>
                    Helper
                </small>
            </h1>
            <div className="d-flex flex-wrap gap-3">
                {companies &&
                    <>
                        <MultiSelectDropdown options={[{ value: managment?.id || '', label: managment?.Name || '' }, ...companies.map(({ id, name_Cia }) => ({ value: id, label: name_Cia }))]} onSelect={handleSelectChange} defaultTitle='Filter Companies' />
                        <Button variant="outline-info" onClick={() => showUserModal(true, {})} >
                            Add User  <i className="fas fa-plus"></i>
                        </Button>
                    </>

                }
            </div>
            <hr />
            {usersFilter && companies &&
                <>
                    <Table hover responsive="lg">
                        <thead>
                            <tr>
                                <th className="text-center">
                                    Name
                                </th>
                                <th className="text-center">
                                    Email
                                </th>
                                <th className="text-center">
                                    Company
                                </th>
                                <th className="text-center">
                                    Is Managment?
                                </th>
                                <th className="text-center">
                                    Created At:
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {usersFilter.map((user) => (
                                <tr key={user.id} onClick={() => showUserModal(true, user)}>
                                    <td className="text-center">{user.name}</td>
                                    <td className="text-center">{user.email}</td>
                                    <td className="text-center">{HelperFunctions.findCompanyNameById(user.companyId, companies) || managment?.Name}</td>
                                    <td className="text-center">{user.managementsId ? <i className="fas fa-check text-green" ></i> : <i className="fas fa-times text-red"></i>}</td>
                                    <td className="text-center">{HelperTime.DateBeautify(user.created_at)}</td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>

                </>
            }

        </div>
    );
};


export default UserMangment

