import { InterfaceHelper } from "../../../Helper/Interface";
import { GetMqtt } from "../../Api/MqttManager";
import { ApiEventTypes, Events } from "../../Events/Events";


export namespace BindingEvent {

    export interface DataChangeEvent {
        type: string,
        objectId: string,
        objectType: string,
        data: any,
    }

    export const Event = <T extends { id: string, type: string }>(res: T): DataChangeEvent => {
        const { type, ...data } = res
        return {
            type: type,
            objectId: data.id,
            objectType: type.split('.')[0],
            data: data,
        }
    }

    export const notify = (sufix: 'add' | 'delete' | 'change' | 'list', e: DataChangeEvent, topic: string) => {

        const action = `data.${sufix}`
        const message = JSON.stringify({
            action,
            body: {
                binding: [e.objectType], id: e.objectId, data: e.data
            }
        })
        GetMqtt().sendMessage(topic, message)
    }

    type base = {
        userId: string,
        companyId: string,
        managementsId: string

    }


    export const notifyChannels = (sufix: 'add' | 'delete' | 'change' | 'list', e: DataChangeEvent, topic: InterfaceHelper.AtLeastOne<base>) => {

        const action = `data.${sufix}`
        const message = JSON.stringify({
            action,
            body: {
                binding: [e.objectType], id: e.objectId, data: e.data
            }
        })
        topic.userId && GetMqtt().sendMessage(`${topic.userId}/${e.objectType}`, message)
        topic.companyId && GetMqtt().sendMessage(`${topic.companyId}/${e.objectType}`, message)
        topic.managementsId && GetMqtt().sendMessage(`${topic.managementsId}/${e.objectType}`, message)
    }

}

export namespace BindTopics {

    export function build<T extends ApiEventTypes>(type: T, r: Events[T]["response"], cid: string) {
        let topic: string[] = []
        switch (type) {
            case type.match(/^(company\.(create|edit|delete))$/)?.[1]:
                const resCompany = r as Events["company.create"]["response"];
                topic = [`${resCompany.id}/company`, `${resCompany.s_managementIds[0]}/company`];
                break;
            case type.match(/^(device\.(edit|create|delete))$/)?.[1]:
                topic = [`${cid}/device`];
                break;
            case type.match(/^(device.storage\.(edit|create|delete))$/)?.[1]:
                const resStorage = r as Events["device.storage.edit"]["response"];
                topic = [`${cid}/${resStorage.deviceId}/device`];
                break;
            case type.match(/^(management\.(edit|create|delete))$/)?.[1]:
                const resManagement = r as Events["management.create"]["response"];
                topic = [`${resManagement.id}/management`];
                break;
            case type.match(/^(report\.(edit|create|delete))$/)?.[1]:
                const resReport = r as Events["report.create"]["response"];
                topic = [`${cid}/${resReport.deviceId}/report`];
                break;
            case type.match(/^(template\.(edit|create|delete))$/)?.[1]:
                topic = [`${cid}/template`];
                break;
            case type.match(/^(senquip\.(edit|create|delete))$/)?.[1]:
                topic = [`${cid}/senquip`];
                break;
            case type.match(/^(lorawanGateway\.(edit|create|delete))$/)?.[1]:
                topic = [`${cid}/lorawanGateway`];
                break;
            case type.match(/^(lorawanDevice\.(edit|create|delete))$/)?.[1]:
                topic = [`${cid}/lorawanDevice`];
                break;
            case type.match(/^(user\.(edit|create|delete))$/)?.[1]:
                topic = [`${cid}/user`];
                break;
            default:
                topic = [];
        }
        return topic
    }


}



