import React from 'react';
import { Form, Button, Dropdown, Alert } from 'react-bootstrap';
import { KeyMap } from '../../../../Helper/Core/interface';
import { IoMdWarning   } from 'react-icons/io';

import { coerceToType, ValueType, isOptionValue, OptionValue } from '../Interface';
import { ControlGenerator, ControlTypes } from './Interface';


import { SVG_Panel, SvgIcon } from '../../SVG';
import { MapComponent, MarkerProps } from '../../../Maps/googlemaps';
import { Typeahead } from "react-bootstrap-typeahead";
import Select from "react-select";
import { ReactSelectDarkTheme } from "../../../../Helper/CustomStyles";
// import 'react-bootstrap-typeahead/css/Typeahead.css';

const getOptionKey = (value: OptionValue): string => {
    if (isOptionValue(value)) {
        return coerceToType(value.value, ValueType.String);
    }
    return coerceToType(value, ValueType.String);
}

const getOptionActive = (value: OptionValue): boolean => {
    if (isOptionValue(value)) {
        return value.active;
    }
    return true;
}

const Generators: { [key in ControlTypes]?: ControlGenerator } = {};

Generators['label'] = (props, state, control) => <p style={{ fontWeight: "bold" }}>{control.value}</p>;

Generators['text'] = (props, state, control) => <>
    <Form.Control type='text' {...control} />
    <Form.Text className="text-muted">
       {'muted' in props && props.muted}
    </Form.Text>
</>;

Generators['line'] = (props, state, control) => <hr />;

Generators['scanner'] = (props, state, control) => <Form.Control autoFocus type='text' onFocus={e => e.target.select()} {...control} />;

Generators['password'] = (props, state, control) => <Form.Control type='password' {...control} />;

// Generators['button'] = (props, state, control) => <Button /*active={props.type === 'button' ? (props.options?.active) : undefined}*/ variant={props.type === 'button' ? (props.style) : undefined} {...control}>{control.value}</Button>;

Generators['checkbox'] = (props, state, control, setState) => {
    control.onChange = (event: any) => {
        const value = event.target.checked;
        setState({ value: value });
    };
    control.value = state.value as any;
    return <Form.Check type='checkbox' defaultChecked={control.defaultValue} checked={control.value} {...control} />;
}

Generators['button'] = (props, state, control, setState) => {
    // return <Button onClick={props.type === 'button' ? props.onClick : undefined} variant={props.type === 'button' ? (props.style) : undefined} {...control}>{control.value}</Button>;
    return <Form.Group>
        <Button onClick={props.type === 'button' ? props.onClick : undefined} variant={props.type === 'button' ? (props.style) : undefined} {...control}>{props.type === 'button' && props.icon} {control.value} </Button>
    </Form.Group>
}

Generators['map'] = (props, state, control, setState) => {

    const coord = props.type === 'map' ? props.defaultCenter : undefined
    const height = props.type === 'map' ? props.height || '250px' : '250px'

    const marker: MarkerProps = {
        position: { lat: coord?.lat || 0, lng: coord?.lng || 0 },
        draggable: true,
        onDrag(event) {
            props.type === 'map' && props.onDrag(event)
        },
    }

    return <>
        <Alert variant="info" className="mt-3 d-flex align-items-center">
            <IoMdWarning size={22}  className="me-2" />
            Drag the marker to set the location
        </Alert>
        <div style={{ height: height, width: '100%' }}>
            <MapComponent
                markers={[marker]}
                height={'100%'}
                width={'100%'}
                zoom={13}
                center={{ lat: coord?.lat || 0, lng: coord?.lng || 0 }} />
        </div>

    </>


}


Generators['date'] = (props, state, control, setState) => {
    control.onChange = (event: any) => {
        setState({ value: coerceToType(event.target.value, ValueType.Date) });
    }
    // coercing date into an ISO string, without timezone attached to end

    const date = coerceToType(state.value, ValueType.Date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const days = date.getDate().toString().padStart(2, '0');
    control.value = state.value ? `${year}-${month}-${days}` : "";
    const oldChange = control.onChange;
    control.onChange = (event: any) => {
        setState({ value: coerceToType(event.target.value, ValueType.Date) });
    };

    return <Form.Control type='date' {...control} />;
};

Generators['datetime'] = (props, state, control, setState) => {
    control.onChange = (event: any) => {
        setState({ value: coerceToType(event.target.value, ValueType.Date) });
    }
    // coercing date into an ISO string, without timezone attached to end

    const date = coerceToType(state.value, ValueType.Date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const days = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    control.value = state.value ? `${year}-${month}-${days} ${hour}:${minutes}` : "";
    const oldChange = control.onChange;
    control.onChange = (event: any) => {
        setState({ value: coerceToType(event.target.value, ValueType.Date) });
    };

    return <Form.Control type='datetime-local' {...control} />;
};

// Generators['datetime'] = (props, state, control, setState) => {
//   control.onChange = (event: any) => {
//     const value = new Date(event);
//     setState({ value: value });
//   }


//   delete (control as any).value;
//   return <DatePicker
//     selected={coerceToType(state.value, ValueType.Date)}
//     showTimeSelect
//     dateFormat="MMMM d, yyyy h:mm aa"
//     customInput={<Form.Control
//       type="text"
//       {...control}
//     />}
//     {...control}
//   />;
// }

// Generators['dotmenu'] = (props, state, control, setState) => {
//   const actions: {
//     [key: string]: {
//       action?: () => void;
//       href?: string | undefined;
//       state?: {
//         [key: string]: any;
//       } | undefined;
//       title: string;
//     };
//   } = {};
//   if (props.type === 'dotmenu' && props.options) {
//     Object.assign(actions, props.options); 
//   }

//   return <div className={props.className}>
//     <Dropdown>
//       <Dropdown.Toggle as={Toggle} id="dropdown-generator">
//         <i className="material-icons">more_horiz</i>
//       </Dropdown.Toggle>
//       <Dropdown.Menu alignRight as={Menu}>
//         {Object.entries(props.options || {}).map(([k, v], i) => {
//           const { href, state } = v;
//           if (href) {
//             return (<Dropdown.Item
//               as={Link}
//               to={{ pathname: href, state }}
//               eventKey={`${i}`}
//               key={k}
//             >
//               {v.title}
//             </Dropdown.Item>);
//           }
//           return (<Dropdown.Item
//             eventKey={`${i}`}
//             key={k}
//             onSelect={(eventKey: any, event: any) => {
//               if (props.state.clicked) {
//                 props.state.clicked(actions[eventKey].title)
//               }
//             }}
//           >
//             {v.title}
//           </Dropdown.Item>);
//         })}
//       </Dropdown.Menu>
//     </Dropdown>
//   </div>;
// }
Generators['typeahead'] = (props, state, control, setState) => {
    const options = Object.entries(state.options);
    const v = state.value;

    return (
        <Typeahead
            id='typeahead'
            clearButton
            options={options.reverse()}
            selected={options.filter(i => v ? i[0] === v : false)}
            {...control}
            labelKey={(i: [string, string]) => i[1]}
            // inputProps={{ className: state.valid === true ? '' : 'is-invalid' }}
            isInvalid={v ? !(options.filter(i => v ? i[0] === v : true).length > 0) : true}
            isValid={v ? options.filter(i => v ? i[0] === v : true).length > 0 : false}
            onBlur={(event: any) => {
                if (props.state.clicked) {
                    props.state.clicked('TypeaheadOnBlur');
                }
            }}
            onInputChange={(event: any) => {
                const value = event;
                options.filter(i => i[0] === value).length > 0 ? props.state.clicked({ valid: true, value: value }) : props.state.clicked({ valid: false });
                setState({ value: value });
            }}
            onChange={(event: string | any[]) => {
                const value = event.length > 0 ? event[0][0] : undefined;
                console.log(value, event)
                if (value) {
                    props.state.clicked({ valid: true, value: value });
                    setState({ value: value })
                }
            }}
        />
    );
};

Generators['multiSelect'] = (props, state, control, setState) => {

    const options = Object.entries(state.options).map(([key, value]) => ({
        value: key,
        label: value
    }));
    const v = Array.isArray(state.value) ? state.value : [];

    const defaultValues = Object.entries(v).map(([key, value]) => ({
        value: key,
        label: value
    }));

    console.log(state.options)


    return (
        <>
            <Select
                options={options as any}
                defaultValue={defaultValues}
                isMulti={true}
                styles={ReactSelectDarkTheme as any}
                onChange={(event: any) => {
                    const labels = (event as any[]).map(e => e.label);
                    props.state.clicked({ valid: true, value: labels });
                    setState({ value: labels })
                }}
            />
            <div className="invalid-feedback">
                {state.feedback}
            </div>
        </>

        // <Select
        //     options={options as any}
        //     defaultValue={defaultValues}
        //     isMulti={true}
        //     styles={ReactSelectDarkTheme as any}
        //     onChange={(event: any) => {
        //         const labels = (event as any[]).map(e => e.label);
        //         props.state.clicked({valid: true, value: labels});
        //         setState({value:labels})
        //     }}
        // />
    );
};

Generators['dropdown'] = (props, state, control, setState) => {
    const reverse: KeyMap<string> = {};
    Object.entries(state.options || {}).map(([key, value]) => {
        reverse[getOptionKey(value)] = key;
    });
    control.onChange = (event: any) => {
        const value = reverse[event.target.value] || event.target.value;
        setState({ value: value });
    }
    if (control.value === '') {
        control.value = undefined as any;
    } else {
        const v = (props.options || {})[control.value];
        if (v) {
            control.value = getOptionKey(v);
        } else {
            control.value = undefined as any;
        }
    }

    return (
        <Form.Select
            // as='select' className="form-select"
            defaultValue={control.value ? undefined : props.placeholder || ''}
            {...control}
        >
            <option selected value="">Select...</option>
            {Object.entries(state.options || {}).filter(
                ([key, value]) => key === control.value
            ).length === 0 ? (
                props.placeholder !== undefined ? (
                    <option disabled={true} value={props.placeholder || ''} key={-1}>
                        {props.placeholder || ''}
                    </option>
                ) : undefined
            ) : undefined}
            {Object.entries(state.options || {}).map(([key, value]) => <option
                value={getOptionKey(value)}
                defaultValue={"dds"}
                disabled={!getOptionActive(value)}
                key={key}
            >
                {getOptionKey(value)}
            </option>
            )}
        </Form.Select>
    );
}

Generators['IconSelect'] = (props, state, control, setState) => {

    const reverse: KeyMap<string> = {};
    Object.entries(state.options || {}).map(([key, value]) => {
        reverse[getOptionKey(value)] = key;
    });
    // control.onChange = (event: any) => {
    //   const value = reverse[event.target.value] || event.target.value;
    //   setState({ value: value });
    // }

    control.onSelect = (eventKey: string | number) => {
        const value = reverse[eventKey] || eventKey;
        setState({ value });
    };

    if (control.value === '') {
        control.value = undefined as any;
    } else {
        const v = (props.options || {})[control.value];
        if (v) {
            control.value = getOptionKey(v);
        } else {
            control.value = undefined as any;
        }
    }


    return (
        <Dropdown  {...control} >
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                {control.value ? (
                    <span>
                        {/* <SvgIcon height={30} width={30} element={SVG_Panel.Icon[control.value]} /> */}
                        {control.value in SVG_Panel.Icon ? <SvgIcon height={30} width={30} element={SVG_Panel.Icon[control.value]} /> : getOptionKey(control.value)}
                    </span>
                ) : (
                    'Select Icon...'
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ minWidth: "100px" }}>
                {Object.entries(state.options || {}).map(([key, value]) => (
                    <Dropdown.Item
                        key={key}
                        eventKey={getOptionKey(value)}
                        active={getOptionKey(value) === control.value}
                        disabled={!getOptionActive(value)}
                    >
                        {getOptionKey(value) in SVG_Panel.Icon ? <SvgIcon height={30} width={30} element={SVG_Panel.Icon[getOptionKey(value)]} /> : getOptionKey(value)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>

        // <Form.Select
        //   // as='select' className="form-select"
        //   defaultValue={control.value ? undefined : props.placeholder || ''}
        //   {...control}
        // >
        //   <option selected value="">Select...</option>
        //   {Object.entries(state.options || {}).filter(
        //     ([key, value]) => key === control.value
        //   ).length === 0 ? (
        //     props.placeholder !== undefined ? (
        //       <option disabled={true} value={props.placeholder || ''} key={-1}>
        //         {props.placeholder || ''}
        //       </option>
        //     ) : undefined
        //   ) : undefined}
        //   {Object.entries(state.options || {}).map(([key, value]) =>
        //     <option
        //       value={getOptionKey(value)}
        //       defaultValue={"dds"}
        //       disabled={!getOptionActive(value)}
        //       key={key}
        //     >

        //       <span>
        //         <SvgIcon height={30} width={30} element={SVG_Panel.Icon["Temperature"]} />  &nbsp;
        //       </span>

        //     </option>
        //   )}
        // </Form.Select>
    );
}


Generators['email'] = (props, state, control) => <Form.Control type='email' {...control} />;

Generators['number'] = (props, state, control) => <Form.Control type='number' {...control} />;

Generators['tel'] = (props, state, control) => <Form.Control type='tel' {...control} />;

Generators['textBlock'] = (props, state, control) => <Form.Control as='textarea' type='text' rows='4' {...control} />;

Generators['time'] = (props, state, control, setState) => {
    control.onChange = (event: any) => {
        const v = coerceToType(event.target.value, ValueType.String);
        const date = coerceToType(state.value, ValueType.Date);
        const [hour, min] = v.split(':').map(v => Number.parseFloat(v));
        const value = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, min);
        if (!Number.isNaN(value.getTime())) {
            setState({ value: value });
        }
    }
    const date = coerceToType(state.value, ValueType.Date);
    control.value = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    return <Form.Control type='time' {...control} />;
}

Generators['url'] = (props, state, control) => <Form.Control type='url' {...control} />;

Generators['file'] = (props, state, setState) => {
    const getFile = (event: any) => {
        if (props.type === 'file') {
            const value = event.target.files;
            setState({ value: "value" });
        }
    };
    return <label className="btn btn-primary" style={{ marginLeft: '10px' }}> <input className='btn-save' type="file" style={{ display: 'none' }} onChange={getFile} /> Upload </label>
}


Generators['color'] = (props, state, control) => <Form.Control type='color' {...control} />;

Generators['radio'] = (props, state, control, setState) => {
    if (props.type === 'radio') {
        control.onChange = (event: any) => {
            let value = event.target.checked;
            let stateValue = value;
            if (props.selectedValue) {
                stateValue = value ? props.selectedValue : undefined;
            }
            setState({ value: value });
        };

        control.checked = coerceToType(state.value, ValueType.Boolean);
        //control.value = control.checked;
        if (props.buttonGroup) {
            (control as any).name = props.buttonGroup;
        }
    }
    return <Form.Check type='radio' {...control} />;
}

Generators['none'] = (props, state, control) => <></>;


export { Generators }