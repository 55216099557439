import { KeyMap } from "../../../Helper/Core/interface";
import * as Yup from 'yup'
import { ValidationResult } from "./Form";
import React from "react";

export type InputValue = string | Date | number | boolean | undefined | InputValue[] | { [key: string]: InputValue };

export type OptionValue = (InputValue | { value: InputValue, active: boolean });

export const isOptionValue = (data: any): data is { value: InputValue, active: boolean } => typeof data === 'object' && typeof data.active === 'boolean' && data.hasOwnProperty('value');

export enum ValueType {
    String,
    Date,
    DateString,
    Number,
    Boolean,
    Undefined,
    Unknown,
    Array,
    Object
}

export const getValueType = (value: InputValue) => {
    if (value === undefined) {
        return ValueType.Undefined;
    }
    if (typeof value === 'boolean') {
        return ValueType.Boolean;
    }
    if (typeof value === 'string') {
        return ValueType.String;
    }
    if (typeof value === 'number') {
        return ValueType.Number;
    }
    if (typeof value === 'object') {
        if (value instanceof Date) {
            return ValueType.Date;
        } else if (Array.isArray(value)) {
            return ValueType.Array;
        }
        return ValueType.Object;
    }

    return ValueType.Unknown;
}

export function coerceToType(value: InputValue, targetType: ValueType.String): string
export function coerceToType(value: InputValue, targetType: ValueType.Number): number;
export function coerceToType(value: InputValue, targetType: ValueType.Boolean): boolean;
export function coerceToType(value: InputValue, targetType: ValueType.Date): Date;
export function coerceToType(value: InputValue, targetType: ValueType.DateString): string;
export function coerceToType(value: InputValue, targetType: ValueType.Array): InputValue[];
export function coerceToType(value: InputValue, targetType: ValueType.Object): { [key: string]: InputValue };
export function coerceToType(value: InputValue, targetType: ValueType): InputValue;
export function coerceToType(value: InputValue, targetType: ValueType) {

    if (targetType === ValueType.Undefined) {
        return value;
    }
    if (targetType === ValueType.String) {
        return (value === undefined || value === null ? '' : value).toString();
    }
    if (targetType === ValueType.Boolean) {
        return value ? true : false;
    }
    if (targetType === ValueType.Number) {
        if (typeof value === 'string') {
            return Number.parseFloat(value);
        } else if (typeof value === 'boolean') {
            return value ? 1 : 0;
        } else if (value instanceof Date) {
            return value.getTime()
        }
        return value ? value : 0;
    }
    if (targetType === ValueType.Date) {
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
            return new Date(value);
        }
        return new Date();
    }
    if (targetType === ValueType.DateString) {
        let date = new Date();
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
            date = new Date(value);
        }
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const hour = date.getHours();
        return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${(hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour)).toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${hour > 11 ? 'PM' : 'AM'}`;
    }
    if (targetType === ValueType.Array) {
        if (typeof value === 'object') {
            if (value instanceof Date) {
                return [value];
            } else if (Array.isArray(value)) {
                return value;
            }
            return Object.values(value);
        }
        return value === undefined ? [] : [value];
    }
    if (targetType === ValueType.Object) {
        if (typeof value === 'object') {
            if (value instanceof Date) {
                return { value };
            } else if (Array.isArray(value)) {
                const r: { [key: string]: InputValue } = {};
                value.forEach((v, i) => {
                    r[i.toString()] = v;
                })
                return r;
            }
            return value;
        }
        return value === undefined ? {} : { value };
    }
    return value;
}

export function coerceToTypes(value: InputValue, targetTypes: ValueType[]) {

    let res = value;
    let match = false;
    targetTypes.forEach((t) => {
        if (match) {
            return;
        }
        const r = coerceToType(value, t);
        if (r === value) {
            match = true;
            res = r;
        }
    });
    if (match) {
        return res;
    }
    return targetTypes.length === 0 ? value : coerceToType(value, targetTypes[0]);
}

export function deepCopy<T>(data: T): T {
    if (data === null) {
        return data;
    } else if (Array.isArray(data)) {
        const r: any = [];
        Object.entries(data).forEach(([key, value]) => {
            r[Number(key)] = deepCopy(value);
        });
        return r
    } else if (data instanceof Date) {
        return new Date(data) as unknown as T;
    } else if (typeof data === 'object') {
        const r: any = {};
        console.log(data)
        Object.entries(data).forEach(([key, value]) => {
            console.log("ABABABA ",value)
            if(value === undefined) return
            r[key] = deepCopy(value);
        });
        return r;
    }

    return data;
}


export interface Base {
    id?: string,
    className?: string,
    style?: string,
    hidden?: boolean,
    disabled?: boolean,
    optional?: boolean,
    feedback?: string,
    isInvalid?: boolean,
    valid?: boolean,
    color?: string,
    inputValueTransform?: (value: InputValue, props: any, state: any) => InputValue,
    outputValueTransform?: (value: InputValue, props: any, state: any) => InputValue,
}

export interface MainInput extends Base {
    type: 'none' | 'text' | 'scanner' | 'textBlock' | 'password' | 'date' | 'datetime' | 'time' | 'number' | 'email' | 'url' | 'tel' | 'color' | 'label' | 'dropdown' | 'checkbox' | 'typeahead' | 'multiSelect' | 'file' | 'imagePreview' | 'line' | 'IconSelect',
    value: InputValue,
    muted?: string,
    valueType?: ValueType,
    label?: string,
    placeholder?: string,
    multiple?:boolean,
    autofill?: string,
    action?: string,
    options?: { [key: string]: OptionValue },
    reverse?: boolean,
    optionFilter?: (item: any) => boolean,
    width?: number | { xl?: number, lg?: number, md?: number, sm?: number, xs?: number } | 'auto',
}

export interface RadioInput extends Base {
    type: 'radio',
    value: InputValue,
    valueType?: ValueType,
    label?: string,
    feedback?: string,
    placeholder?: string,
    autofill?: string,
    action?: string,
    options?: { [key: string]: OptionValue },
    optionFilter?: (item: any) => boolean,
    width?: number | { xl?: number, lg?: number, md?: number, sm?: number, xs?: number } | 'auto',
    selectedValue?: InputValue,
    buttonGroup?: string,
}

export interface ButtonInput extends Base {
    type: 'button',
    value: InputValue,
    icon?: React.ReactNode,
    valueType?: ValueType,
    label?: string,
    feedback?: string,
    placeholder?: string,
    autofill?: string,
    action?: string,
    onClick: (event: any) => void,
    options?: { [key: string]: OptionValue },
    optionFilter?: (item: any) => boolean,
    width?: number | { xl?: number, lg?: number, md?: number, sm?: number, xs?: number } | 'auto',
    style?: string,
    //size?: string,
    active?: string
}

export interface MapInput extends Base {
    type: 'map',
    value: InputValue,
    defaultZoom: number,
    defaultCenter: { lat: number, lng: number }
    valueType?: ValueType,
    label?: string,
    feedback?: string,
    placeholder?: string,
    autofill?: string,
    action?: string,
    height?: `${number}px` | `${number}%` | `${number}vh`,
    onDrag: (event: any) => void,
    options?: { [key: string]: OptionValue },
    optionFilter?: (item: any) => boolean,
    width?: number | { xl?: number, lg?: number, md?: number, sm?: number, xs?: number } | 'auto',
    style?: string,
    //size?: string,
    active?: string
}


export interface DotMenuInput extends Base {
    type: 'dotmenu',
    value: InputValue,
    options?: {
        [key: string]: {
            href?: string,
            state?: { [key: string]: any },
            title: string,
        }
    },
    width?: number | { xl?: number, lg?: number, md?: number, sm?: number, xs?: number } | 'auto',
    placeholder?: string,
    autofill?: string,
    label?: string,
    feedback?: string,
    action?: string,
    valueType?: ValueType,
}

export type Input = MainInput | ButtonInput | RadioInput | DotMenuInput | cxInput | MapInput;

export const getWidth = (width: Input['width']): { xl?: number, lg?: number, md?: number, sm?: number, xs?: number } => {
    const groupProps: { [key: string]: any } = {
        xs: 12,
        md: 6,
    };
    if (width === 'auto') {
        Object.assign(groupProps, {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 1,
            xs: 1,
        });
    } else if (typeof width === 'number') {
        groupProps.md = width;
    } else {
        Object.assign(groupProps, width || {});
    }
    return groupProps;
}

export const getWidthWithAuto = (width: Input['width']): { xl?: number | 'auto', lg?: number | 'auto', md?: number | 'auto', sm?: number | 'auto', xs?: number | 'auto' } => {
    const groupProps: { [key: string]: any } = {
        xs: 12,
        md: 6,
    };
    if (width === 'auto') {
        Object.assign(groupProps, {
            xl: 'auto',
            lg: 'auto',
            md: 'auto',
            sm: 'auto',
            xs: 'auto',
        });
    } else if (typeof width === 'number') {
        groupProps.md = width;
    } else {
        Object.assign(groupProps, width || {});
    }
    return groupProps;
}

export interface cxInput extends Base {
    type: 'document' | 'image' | 'sound' | 'resident' | 'user' | 'building' | 'apartment' | 'site' | 'typeahead' | 'imageUpload',
    value: InputValue,
    valueType?: ValueType,
    label?: string,
    feedback?: string,
    placeholder?: string,
    autofill?: string,
    action?: string,
    options?: { [key: string]: InputValue },
    optionFilter?: (item: any) => boolean,
    width?: number | { xl?: number, lg?: number, md?: number, sm?: number, xs?: number },
}

export interface Collection<T> extends Base {
    type: 'collection',
    items: T[],
}
export interface Group extends Base {
    type: 'group',
    items: (Input | Collection<Input>)[],
}

export interface Row extends Base {
    type: 'row',
    items: (Input | Group | Collection<Input | Group>)[],
}

export interface Section extends Base {
    type: 'section',
    title?: string,
    items: (Input | Group | Row | List | Collection<Input | Group | Row | List>)[],
};

export interface List extends Base {
    type: 'list',
    title?: string,
    value: InputValue,
    valueType?: ValueType,
    itemKey?: string,
    items: (Input | Group | Row | Collection<Input | Group | Row>)[],
}

export type FormScaffold = {
    node: JSX.Element,
    element: Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>,
    children: FormScaffold[],
    id: string,
    parent?: FormScaffold,
}
export interface BaseFormAction {
    setFormValidation: (validated: boolean) => void,
    getValue: (inputId: string) => InputValue,
    getValid: (inputId: string) => boolean,
    getValidId: (inputId: string) => boolean,

    getFormChanged: () => void;

    getFormValidation: () => boolean | undefined,
    getYupValidation: (schema: Yup.Schema<any>) => Promise<ValidationResult>

    setValue: (inputId: string, value: InputValue) => void,
    setValid: (inputId: string, valid: boolean, message?: string) => void,
    setDisabled: (inputId: string, disabled: boolean) => void,
    setMultipleDisabled: (disabled: boolean, ...inputId: string[]) => void,
    setColor: (inputId: string, color: string) => void,
    setLabel: (inputId: string, label: string) => void,
    setFeedbackMessage: (inputId: string, feedback: string) => void,
    setIsInvalid: (inputId: string, isInvalid: boolean) => void,


    setHidden: (inputId: string, hidden: boolean) => void,
    setMultipleHidden: (hidden: boolean, ...inputId: string[]) => void,

    setOptions: (inputId: string, options: { [key: string]: InputValue }) => void,

    replaceFormContent: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], nodeId?: string | undefined) => void,
    removeFormNode: (nodeId: string) => KeyMap<any>,

    addFormContent: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], nodeId: string, bindingData?: InputValue,) => void,
    addFormContentLast: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], bindingData?: InputValue) => void,
    generateFormNode: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], idPrefix: string, bindingData?: InputValue, state?: KeyMap<any>) => FormScaffold[],
}

export interface FormAction<T> extends BaseFormAction {
    getFormData: () => T,
}