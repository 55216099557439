import React from 'react';
import { Navbar, Button, Container } from 'react-bootstrap';
import { GrMenu } from "react-icons/gr";

interface IHeaderProps {
    toggleSidebar: () => void;
    show: boolean
}

const Header: React.FC<IHeaderProps> = ({show, toggleSidebar}) => {
    return (
        <Navbar 
            bg="dark" 
            variant="dark" 
            expand="lg" 
            sticky="top" 
            className="border-secondary py-2 navbarContainer"
            
        >
            <Container fluid>
                <Button
                    variant="outline-light"
                    onClick={toggleSidebar}
                    disabled={show && (window.innerWidth < 1200)}
                    className="me-2"
                >
                    <GrMenu size={20} />
                </Button>

                <Navbar.Brand className="mx-auto" href="/">
                    <img
                        src="/images/SierraNetLogo.png"
                        alt="SierraNet Logo"
                        style={{ 
                            maxWidth: '240px', 
                            height: 'auto', 
                            maxHeight: '55px',
                            objectFit: 'contain'
                        }}
                    />
                </Navbar.Brand>

                {/* Right side placeholder for future elements */}
                <div style={{ width: '42px' }}></div>
            </Container>
        </Navbar>
    );
};

export default Header;