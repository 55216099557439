import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRedux } from '../../../Interfaces/redux';
import { Button, Card, Col, Row, Collapse } from 'react-bootstrap';
import { Panel, PanelBody } from '../../panel/panel';
import { useNavigate } from 'react-router-dom';
import { HiOutlineDocumentAdd, HiOutlineDocumentReport } from 'react-icons/hi';
import { CiEdit } from 'react-icons/ci';
import { RiErrorWarningFill } from "react-icons/ri";
import { BindingHooks } from '../../../Server/Binding/bidings/BindingHook';
import { ApiInterfaces } from '../../../Interfaces';
import { FaAd, FaPlus } from 'react-icons/fa';

import APIManager from '../../../Server/Api/APIInterface';
import { MdDetails, MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';

import { TbListDetails } from 'react-icons/tb';
import useModalCommunication from '../../Utils/Hooks/modal';
import WirelessDeviceModal from './modal';

const LoraWanDevice: React.FC = () => {
    const { user } = useSelector((state: IRedux.MapState) => state.auth);
    const { data } = BindingHooks.WirelessDevice(user.managementsId || '', 'LoraWanWirelessDevice', true);
    const { data: managment } = BindingHooks.Management(user.managementsId || '', 'Management', true)



    const { isOpen, openModal: open, closeModal } = useModalCommunication('LoraWanDeviceModal');
    const [device, setDevice] = useState<Partial<ApiInterfaces.WirelessDevice>>({});

    const [devices, setDevices] = useState<ApiInterfaces.ListDevices | undefined>(undefined)


    useEffect(() => {
        const fetchData = async () => {
            const res = await APIManager.snRequest(`management.devices.list`, { id: user.managementsId || "" })
            if (res.type === 'management.devices.list') setDevices(res.data)
        }
        fetchData()
    }, []);



    const openModal = (device: Partial<ApiInterfaces.WirelessDevice>) => {
        setDevice(device);
        open();
    };


    return (
        user.managementsId === process.env.REACT_APP_ROOT ? (
            device ? (
                <>
                    <WirelessDeviceModal show={isOpen} handleClose={() => {
                        closeModal();
                        setDevice({});
                    }} key={device.id || new Date().getTime()} device={device}  companies={managment?.companies || []} />

                    <Row className="justify-content-center">
                        <Col xs={12} md={8} xl={6}>
                            <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }}>
                                <PanelBody className="p-6">
                                    <h3 className="text-center"> LoraWAN Devices </h3>
                                </PanelBody>
                            </Panel>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8} xl={6}>
                            <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }}>
                                <PanelBody className="p-6">
                                    <Button variant={'outline-warning'} className="d-flex align-items-center mb-3" onClick={() => open()}>
                                        <FaPlus size={18} className="me-2" /> Add Device
                                    </Button>
                                    <hr />
                                    <div className="mt-3">
                                        {data?.map((device, index) => (
                                            <Card key={index} className="mb-3 shadow border rounded" style={{ borderColor: '#6c757d' }}>
                                                <Card.Body className="d-flex justify-content-between align-items-center">
                                                    <span className="d-flex align-items-center ps-3">
                                                        <HiOutlineDocumentReport size={20} className="me-2 text-primary" />
                                                        <strong>{device.description}</strong>
                                                    </span>
                                                    <div className="d-flex">
                                                        <Button variant="outline-warning" className="me-2 d-flex align-items-center" onClick={() => openModal(device)}>
                                                            <TbListDetails size={20} className="me-1" />
                                                            <span className="d-none d-md-inline-block">Details</span>
                                                        </Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                </PanelBody>
                            </Panel>
                        </Col>
                    </Row>

                </>
            ) : <></>
        ) : (
            <Row className="justify-content-center mt-5">
                <Col xs={12} md={6} xl={4}>
                    <Card className="shadow">
                        <Card.Body className="text-center p-5">
                            <h3 className="mb-3">Not Authorized</h3>
                            <p className="text-muted mb-0">You do not have permission to access this page.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    );
};

export default LoraWanDevice;