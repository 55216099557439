import JSZip from 'jszip';
import { ApiInterfaces } from '../../../Interfaces';

export async function generateSetupZip(setup: ApiInterfaces.AWS_IOT_Gateway, description: string, name: string): Promise<void> {
    const zip = new JSZip();
    const info = `gatewayId = ${setup.gatewayId}\ndescription = ${description}\nname = ${name}\nCertificate_ID = ${setup.certificateId}`
    zip.file("info.txt", info);
    zip.file("client.cert.pem", setup.certificatePem);
    zip.file("client.private.key", setup.privateKey);
    const content = await zip.generateAsync({ type: "blob" });
    const blobUrl = URL.createObjectURL(content);
    // Create a temporary anchor element and trigger the download
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = "SetupInformation.zip";
    document.body.appendChild(a); // Append the anchor to the body
    a.click(); // Simulate click to trigger download
    document.body.removeChild(a); // Remove the anchor from the body
    URL.revokeObjectURL(blobUrl); // Clean up the blob URL
}