// Sidebar.tsx
import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { ApiInterfaces } from '../../Interfaces';
import { SVG, SVG_Panel, SvgIcon } from '../Utils/SVG';
import { Button, Card, } from 'react-bootstrap';
import { GetMqtt } from '../../Server/Api/MqttManager';
import { IoT } from '../../Helper/IoT';
import { Subscription } from 'rxjs';
import { IoTGetLastResponse } from '../../Server/Events/base';
import DeviceEditModal from '../Devices/Edit'
import { HelperTime } from '../../Helper/Time';
import useModalCommunication from '../Utils/Hooks/modal';


const Settings: React.FC<{ device: ApiInterfaces.Device }> =
    ({ device, }) => {
        const { isOpen, openModal, closeModal } = useModalCommunication('DeviceEditModal');
        const [payload, setPayload] = useState<JSON | IoTGetLastResponse | undefined>(undefined);
        const [visual, setVisual] = useState<{ status: string, display: string }>({ status: "", display: "" });
        const { accessToken } = useSelector((state: IRedux.MapState) => state.auth);

        // const instance = MqttManager.getInstance(mqttToken || '').getMqttService();

        useEffect(() => {
            const getLastValue = async (dev: ApiInterfaces.Device) => {
                const data = await IoT.getLastValue(dev, accessToken)
                if (data) payloadHandler(data)
            }
            const stream: Subscription | undefined = device.IO ? GetMqtt().getMessage(`${device.companyId}/${device.id}/Live/`).subscribe((message) => {
                payloadHandler(JSON.parse(message))
                stream?.unsubscribe();
            }) : undefined;

            !device.IO && getLastValue(device);


            return () => {
                stream?.unsubscribe();
            };
        }, []);

        const payloadHandler = (message: JSON | IoTGetLastResponse) => {
            console.log("AAA", message)
            let status = ''
            let display = ''

            if ('type' in message && !message.payload) {
                status = 'Offline'
                display = 'Offline'
            } else if ('type' in message && message.payload) {
                const check = new Date().getTime() - (2 * 24 * 60 * 60 * 1000) //check if last data is older than 36 hours
                status = new Date(message.timestamp).getTime() < check ? 'Offline' : 'Online'
                display = HelperTime.formatRelativeTime(message.timestamp.toString())
            } else {
                status = 'Online'
                display = 'Online'
            }
            setPayload('timestamp' in message ? message.payload : message)
            setVisual({ status, display })
        }


        return (
            <>
                {payload &&
                    <DeviceEditModal show={isOpen} handleClose={closeModal} device={device} key={device.id}
                        payload={payload} />}

                <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div className="mx-auto">{device.name}
                        </div>
                        {payload && <Button size='sm' variant='primary' onClick={() => openModal()}><i
                            className="far fa-edit"></i> </Button>}
                    </Card.Header>

                    <Card.Body>
                        <Card.Text>
                            <div className="d-flex justify-content-center p-4">
                                {SVG.Icon.hasOwnProperty(device.class) &&
                                    <SvgIcon height={70} width={70} element={SVG.Icon[device.class]} />}
                            </div>
                            <hr />
                            <div className="d-flex justify-content-center">
                                <>
                                    <SvgIcon height={30} width={30}
                                        element={SVG_Panel.Icon[visual.status === "" ? 'Sync' : visual.status]} />  &nbsp;
                                    <p className='fw-bolder m-0 ms-2'>{visual.display === "" ? 'Syncing...' : visual.display}</p> &nbsp;
                                </>

                            </div>

                        </Card.Text>

                    </Card.Body>
                </Card>

            </>
        );
    };


export default Settings;
