import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting'
import ExportData from 'highcharts/modules/export-data'
import { ApiInterfaces } from '../../Interfaces';
import { IoT } from '../../Helper/IoT';
import { connect, useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button, Container, Form } from 'react-bootstrap';
import { GraphBuilder } from './Default';

import Metadata = GraphBuilder.Metadata;

type IProps = {
    device: ApiInterfaces.Device | ApiInterfaces.Senquip,
    key: string,
    height?: number,
    customMetadata?: Metadata[]
    forwardedRef?: React.RefObject<any>;
    series?: Highcharts.SeriesOptionsType[]
    shared?: boolean,
    type: "area" | "line" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "treemap" | "histogram" | "column",
    panel?: boolean,
    reducer?: (data: Highcharts.SeriesOptionsType & { data: any }[]) => void
}

if (typeof Highcharts === 'object') {
    HighchartsExporting(Highcharts)
    noDataToDisplay(Highcharts)
    ExportData(Highcharts)
}
type DateRange = {
    start: Date,
    finish: Date
}
const Chart: React.FC<IProps> = (props: IProps) => {
    const { device, type, shared, customMetadata, reducer, forwardedRef, series } = props
    const { accessToken } = useSelector((state: IRedux.MapState) => state.auth)
    const metadata = customMetadata || ('graph_metadata' in device ?
        JSON.parse(JSON.stringify(device.graph_metadata) || '') :
        []) as GraphBuilder.Metadata[]
    const [dateRange, setDateRange] = useState<DateRange>({
        start: new Date(new Date().setDate(new Date().getDate() - 2)),
        finish: new Date()
    });
    const [show, setShow] = useState<Boolean>(false);
    const menus = [
        {
            useHTML: true,
            text: '24 <span style="color:red;">Hours</span>',
            onclick: () => handleUpdateGraphManual('Day')
        },
        { useHTML: true, text: '7 <span style="color:red;">Days</span>', onclick: () => handleUpdateGraphManual('Week') },
        {
            useHTML: true,
            text: '1 <span style="color:red;">Month</span>',
            onclick: () => handleUpdateGraphManual('Month')
        },
        {
            useHTML: true,
            text: '3 <span style="color:red;">Months</span>',
            onclick: () => handleUpdateGraphManual('3Month')
        },
        { useHTML: true, text: '1 <span style="color:red;">Year</span>', onclick: () => handleUpdateGraphManual('Year') },
        {
            useHTML: true, text: '<span style="color:red;">All Data</span>', onclick: () => {
                setChartOptions({ series: [] });
                getAllValue(device)
            }
        },
        {
            useHTML: true, text: `<span style="color:black;">Show/Hide Custom Date</span>`, onclick: () => {
                setShow((prev) => (!prev));
            }
        },
    ]
    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(
        {
            ...GraphBuilder.Options('serial' in device ? device.serial : device.name, shared || true),
            exporting: {
                enabled: true,
                csv: { itemDelimiter: ';' },
                buttons: {
                    contextButton: { enabled: false },
                    ExportButton: {
                        text: `<i class="fas fa-bars" ></i>`,
                        x: -10,
                        menuItems: ['viewFullscreen', 'exitFullscreen', "separator", 'downloadPNG', 'downloadPDF', 'downloadCSV']
                    },
                    customButton: props.series ? {} : {
                        text: `<i class="far fa-calendar-check"></i>`,
                        x: -50,
                        menuItems: menus as any
                    },
                },
            }

        }
    );

    const getAllValue = async (dev: ApiInterfaces.Device | ApiInterfaces.Senquip) => {
        console.log(shared)
        let res;
        if ('serial' in dev) {
            //res = await IoT.getSenquipValue(dev, accessToken)
        } else {
            res = await IoT.getAllValue(dev, accessToken)
        }
        if (res) {
            setChartOptions({ series: GraphBuilder.generateHighChartsWithMetadata(res.data, metadata, type, shared) });
        }
    }

    const handleUpdateGraphManual = async (name: 'Day' | 'Week' | 'Month' | '3Month' | 'Year' | '', date?: Boolean, firstSkip?: boolean) => {
        firstSkip === undefined && setChartOptions({ series: [] });
        const finish = date ? dateRange.finish : new Date();
        const start = date ? dateRange.start : (() => {
            switch (name) {
                case 'Day':
                    return new Date(new Date().setDate(new Date().getDate() - 1));
                case 'Week':
                    return new Date(new Date().setDate(new Date().getDate() - 7));
                case 'Month':
                    return new Date(new Date().setMonth(new Date().getMonth() - 1));
                case '3Month':
                    return new Date(new Date().setMonth(new Date().getMonth() - 3));
                case 'Year':
                    return new Date(new Date().setMonth(new Date().getMonth() - 12));
                default:
                    return new Date();
            }
        })();

        let series;
        if ('serial' in device) series = await GraphBuilder.GetDataSenquip(device, start, type, finish, customMetadata, shared)
        else series = await GraphBuilder.GetData(device, start, type, finish, customMetadata, shared)
        reducer && reducer(series as any)
        setChartOptions({ series })
    }

    const handleDateTimeChange = (dateType: keyof DateRange) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = new Date(event.target.value);
        setDateRange(prevDateRange => ({ ...prevDateRange, [dateType]: selectedDate }));
    };

    useEffect(() => {
        console.count("Mounting ")
        !props.series ? handleUpdateGraphManual('Week', undefined, true) : setChartOptions({ series: props.series })
    }, []);

    useEffect(() => {
        if (series) {
            setChartOptions(prevOptions => ({ ...prevOptions, series }));
        }
    }, [series]);

    return (
        <>
            {show && (
                <Container fluid className="mb-3">
                    <div className="p-3 rounded mx-1 d-flex flex-wrap gap-3 align-items-end justify-content-end">
                        <Form.Group>
                            <Form.Label className="text-muted  mb-1">Start Date</Form.Label>
                            <Form.Control
                                //size="sm"
                                type="datetime-local"
                                value={new Date(dateRange.start.getTime() - (dateRange.start.getTimezoneOffset() * 60000))
                                    .toISOString()
                                    .slice(0, 16)}
                                onChange={handleDateTimeChange('start')}
                                max={new Date(dateRange.finish.getTime() - (dateRange.finish.getTimezoneOffset() * 60000))
                                    .toISOString()
                                    .slice(0, 16)}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="text-muted  mb-1">End Date</Form.Label>
                            <Form.Control
                                //size="sm"
                                type="datetime-local"
                                value={new Date(dateRange.finish.getTime() - (dateRange.finish.getTimezoneOffset() * 60000))
                                    .toISOString()
                                    .slice(0, 16)}
                                onChange={handleDateTimeChange('finish')}
                                min={new Date(dateRange.start.getTime() - (dateRange.start.getTimezoneOffset() * 60000))
                                    .toISOString()
                                    .slice(0, 16)}
                                max={new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))
                                    .toISOString()
                                    .slice(0, 16)}
                            />
                        </Form.Group>

                        <Button
                            //size="sm" 
                            variant="warning"
                            className="px-4"
                            onClick={() => handleUpdateGraphManual('', true)}
                        >
                            <i className="fas fa-sync-alt me-2"></i>
                            Apply Range
                        </Button>
                    </div>
                </Container>
            )}
            <div>
                <HighchartsReact
                    ref={forwardedRef}
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>

        </>
    )
}

export default Chart


