const handleQR_Print = (qrRef:  React.RefObject<HTMLDivElement>) => {
    if (qrRef.current) {
        const printContent = qrRef.current.innerHTML;
        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print QR Code</title>
                        <style>
                            body {
                                margin: 0;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                                background-color: #fff;
                            }
                            .qr-card {
                                display: inline-block;
                                padding: 20px;
                                border: 1px solid #ccc;
                                border-radius: 10px;
                                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            }
                        </style>
                    </head>
                    <body>${printContent}</body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
        }
    }
};

export default handleQR_Print;