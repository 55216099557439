import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Components/MainPage/indexC';
import { useSelector } from 'react-redux';
import { store } from './store';
import PublicRoute from './Components/Redirect/PublicRoute';
import { IRedux } from './Interfaces/redux';
import APIManager from './Server/Api/APIInterface';
import { refreshAuthTokens } from './redux/actions/authAction';
import BaseAlert from './Alert/BaseAlert';
import ProtectedRoute from './Components/Redirect/ProtectedRoute';
import Login from './Components/Login/login';
import Header from './Components/header/header';
import './Components/MainPage/WeatherIcons/weatherIcons.css'
import UserNotification from './Components/UserNotification/UserNotification';
import UserManagment from './Components/UserManagment';
import Forgot from './Components/Login/forgot';
import { Container } from 'react-bootstrap';
import Sidebar from './Components/LeftMenu/Sidebar';
import DeviceSettings from './Components/LeftMenu/DeviceList';
import CompanyList from './Components/LeftMenu/CompanyList';
import MapView from './Components/Managment/MapView';
import ReduxLoader from './Components/Utils/ReduxLoader';
import MqttManager from './Server/Api/MqttManager';
import ChartList from './Components/LeftMenu/ChartList';
import ReportBuilder from "./Components/LeftMenu/ReportBuilder";
import TemplateView from "./Components/Reports/TemplateView";
import Senquip from './Components/Senquip';
import LoraWanIndex from './Components/LoraWan';

function App(): JSX.Element {

    const [showSidebar, setShowSidebar] = useState(false);
    const { user, accessToken, refreshToken, mqttToken, isLoggedIn } = useSelector((state: IRedux.MapState) => state.auth)
    const toggleSidebar = useCallback(() => {
        setShowSidebar(prev => !prev);
    }, []);

    const closeSidebar = useCallback(() => {
        if (showSidebar) setShowSidebar(false);
    }, [showSidebar]);

    useEffect(() => {

        const initializeApp = () => {
            console.count("HELO MOTHER F")
            APIManager.setEndpoint(process.env.REACT_APP_API);
            APIManager.setWSEndpoint(process.env.REACT_APP_API);
            const searchParams = new URLSearchParams(window.location.search);
            const expoToken = searchParams.get('token');
            if (expoToken) window.localStorage.setItem('expo', expoToken);

        };
        initializeApp()

    }, []);

    function onRender() {
        if (user === undefined) {
            return guest();
        }
        APIManager.setRefreshToken(user.name, refreshToken, 'sierra.net.api');
        APIManager.setAccessToken(accessToken);
        APIManager.setMqttToken(user.name, mqttToken, 'sierra.net.api');
        APIManager.registerAuthUpdate((newToken, newrefreshToken, newMqttToken) => {
            store.dispatch(
                refreshAuthTokens(newToken, newrefreshToken, newMqttToken)
            );
            APIManager.setRefreshToken(user.name, newrefreshToken, 'sierra.net.api');
            APIManager.setMqttToken(user.name, newMqttToken, 'sierra.net.api');
            MqttManager.Reconnect(newMqttToken)
        });
    };

    function guest() {
        return <div className="App w-100" data-bs-theme="dark">
            <BaseAlert />
            <Container fluid className={`main-container ${showSidebar ? 'shifted-content' : ''}`}>
                <Routes>
                    <Route
                        path="/login"
                        element={<PublicRoute isAllowed={!isLoggedIn}> <Login /> </PublicRoute>}
                    />
                    <Route
                        path="/resetpassword"
                        element={<PublicRoute isAllowed={!isLoggedIn}> <Forgot /> </PublicRoute>}
                    />
                    <Route path="*" element={<Navigate to={'/login'} replace />} />
                </Routes>
            </Container>
        </div>
    }

    return (
        onRender() ||
        <>
            <Sidebar show={showSidebar} closeSidebar={closeSidebar} />
            <Header show={showSidebar} toggleSidebar={toggleSidebar} />
            <UserNotification />
            <BaseAlert />
            <ReduxLoader />
            <div className="App w-100" data-bs-theme="dark">
                <Container fluid className={`main-container ${showSidebar ? 'shifted-content' : ''}`}>
                    <Routes>
                        <Route
                            path="/"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <Home /> </ProtectedRoute>}
                        />
                        <Route
                            path="/usermanagment"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <UserManagment /> </ProtectedRoute>}
                        />
                        <Route
                            path="/devices"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <DeviceSettings /> </ProtectedRoute>}
                        />
                        <Route
                            path="/charts"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <ChartList /> </ProtectedRoute>}
                        />
                        <Route
                            path="/build.graph"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <ChartList /> </ProtectedRoute>}
                        />
                        <Route
                            path="/companies"
                            element={
                                <ProtectedRoute isAllowed={isLoggedIn}> <CompanyList /> </ProtectedRoute>}
                        />
                        <Route
                            path="/mapview"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <MapView /> </ProtectedRoute>}
                        />
                        <Route
                            path="/clientPage"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <Home /> </ProtectedRoute>}
                        />

                        <Route
                            path="/reportBuilder"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <ReportBuilder /> </ProtectedRoute>}
                        />

                        <Route
                            path="/senquip"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <Senquip /> </ProtectedRoute>}
                        />

                        <Route
                            path="/template/:id"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <TemplateView /> </ProtectedRoute>}
                        />

                        <Route
                            path="/lorawan"
                            element={<ProtectedRoute isAllowed={isLoggedIn}> <LoraWanIndex /> </ProtectedRoute>}
                        />

                        <Route
                            path="/login"
                            element={<PublicRoute isAllowed={!isLoggedIn}> <Login /> </PublicRoute>}
                        />
                        <Route
                            path="/resetpassword"
                            element={<PublicRoute isAllowed={!isLoggedIn}> <Forgot /> </PublicRoute>}
                        />

                    </Routes>
                </Container>
            </div>
        </>

    )
}

export default App
