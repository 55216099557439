import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Gateway from './Gateway';
import LoraWanDevice from './Devices';
import { Panel, PanelBody } from '../panel/panel';

const LoraWanIndex: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('gateways');

    return (
        <>
             <style>
                {`
                .panel-hover {
                    border: 1px solid transparent; /* Always have a border, but make it transparent */
                }
                .panel-hover:hover {
                    border: 1px solid red; /* Only change the color on hover */
                }
                .hidden {
                    display: none;
                }
                `}
            </style>
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={6} md={4} xl={3} onClick={() => setActiveTab('gateways')}>
                        <Panel theme={'inverse'} reload={false}
                               className="panel-hover"
                               style={{
                                   marginBottom: '0rem',
                                   cursor: 'pointer',
                                   width: '100%',
                                   backgroundColor: activeTab === 'gateways' ? '#161a1d' : '#2c3135eb',
                                   color: activeTab === 'gateways' ? 'white' : 'black'
                               }}>
                            <PanelBody className="p-6">
                                <h3 className="text-center">
                                    Gateways
                                </h3>
                            </PanelBody>
                        </Panel>
                    </Col>
                    <Col xs={6} md={4} xl={3} onClick={() => setActiveTab('devices')}>
                        <Panel theme={'inverse'} reload={false}
                               className="panel-hover"
                               style={{
                                   marginBottom: '0rem',
                                   cursor: 'pointer',
                                   backgroundColor: activeTab === 'devices' ? '#161a1d' : '#2c3135eb',
                                   color: activeTab === 'devices' ? 'white' : 'black'
                               }}>
                            <PanelBody className="p-6">
                                <h3 className="text-center">
                                    Devices
                                </h3>
                            </PanelBody>
                        </Panel>
                    </Col>
                </Row>
                <div className={activeTab === 'gateways' ? '' : 'hidden'}>
                    <Gateway />
                </div>
                <div className={activeTab === 'devices' ? '' : 'hidden'}>
                    <LoraWanDevice />
                </div>
            </Container>
        </>
    );
};

export default LoraWanIndex;
