import React from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';

import { Input, cxInput, InputValue, coerceToType, ValueType, getWidthWithAuto } from './Interface';
import { Base, Props as BaseProps, State as BaseState } from './Base';

import { Generators } from './control/Generators';

export type Props = BaseProps & Input;

export interface State extends BaseState {

}

const randomId = () => {
  return `input-${Math.random().toString().slice(2)}`
}

export class Control extends Base<Props, State> {

  public constructor(props: Props) {
    super(props, {
      value: props.value,
      options: props.options || {},
      disabled: props.disabled,
      color: props.color,
      label: props.label,
      feedback: props.feedback,
      isInvalid: props.isInvalid,
      valid: props.valid
    });
  }
  protected bindingValueTransform(value: InputValue) {
    if (this.props.type === 'radio') {
      if (value) {
        return this.props.selectedValue === undefined ? value : this.props.selectedValue;
      }
    }
    return value;
  }
  protected valid(value: InputValue) {
    return true;
  }

  public render() {
    this.bindState()
    if (!this.shouldRender()) {
      return <></>;
    }
    const groupProps = getWidthWithAuto(this.props.width);
    const autofill = this.props.autofill || randomId();
    const controlProps = {
      onChange: (event: any) => {
        const value = (this.props.outputValueTransform || (v => v))(event.target.value, this.props, this.state);
        this.props.state.valueChanged(value);
        this.setState({ value: value });
      },
      required: this.state.optional === false,
      key: this.props.id,
      disabled: this.state.disabled,
      placeholder: this.props.placeholder || this.props.label,
      autoComplete: autofill,
      name: autofill,
      id: autofill,
      value: coerceToType((this.props.inputValueTransform || (v => v))(this.state.value, this.props, this.state), ValueType.String),
      className: this.props.className,
      // isInvalid: this.state.valid === false,
      // isValid: this.state.valid,
      isInvalid: this.state.isInvalid,
      style: { backgroundColor: this.state.color }
    };


    const action = this.props.action;
    if (action) {
      (controlProps as any).onClick = (event: any) => {
        this.props.state.clicked(action)
      }
    }

    const generator = Generators[this.props.type];
    return <Form.Group className="mb-3" as={Col}   {...groupProps}>

      {this.props.label === undefined ? undefined : <Form.Label style={{ fontWeight: "bold", backgroundColor: this.state.color }}>{this.state.label}&nbsp;  </Form.Label>}
      {generator ? generator(this.props, { ...this.state, value: (this.props.inputValueTransform || (v => v))(this.state.value, this.props, this.state) }, controlProps, (s) => {
        if (s.hasOwnProperty('value')) {
          s.value = (this.props.outputValueTransform || (v => v))(s.value, this.props, this.state);
          this.props.state.valueChanged(s.value);
        }
        this.setState(s)
      }) : <Form.Control type={this.props.type} {...controlProps} />}

      {this.state.feedback && <Form.Control.Feedback type="invalid">
        <p className='text-center'>{this.state.feedback}</p>
      </Form.Control.Feedback>}



    </Form.Group>;
  }
}
