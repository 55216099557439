// Sidebar.tsx
import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { Col, Row, Card, Button } from 'react-bootstrap';
import Settings from './Settings';
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { SVG, SVG_Panel, SvgIcon } from '../Utils/SVG';
import { MdOutlineQrCodeScanner, MdQrCode2 } from 'react-icons/md';
import CreateDeviceModal from '../Devices/Create';
import APIManager from '../../Server/Api/APIInterface';
import { ApiInterfaces } from '../../Interfaces';
import useModalCommunication from '../Utils/Hooks/modal';



const DeviceSettings: React.FC = () => {
    const { user } = useSelector((state: IRedux.MapState) => state.auth)
    const { data ,isLoading} = BindingHooks.Device(user.companyId, 'DevList', true)
    const { isOpen, openModal, closeModal } = useModalCommunication('CreateDeviceModal');
    const [company, setCompany] = useState<ApiInterfaces.Company | undefined>(undefined);


    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const res = await APIManager.snRequest('company.only.get', { id: user.companyId });
            if (res.type === 'company.only.get') setCompany(res);
        };
        fetchCompanyInfo();
    }, []);

    return (
        <>
            <Row>
                {data && data.map((x) => (
                    <Col sm='6' xl='3'>
                        <Settings device={x} key={x.id} />
                    </Col>
                ))}

                {!isLoading && <Col sm='6' xl='3'>
                    <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <div className="mx-auto">Add New Device</div>
                            <Button size='sm' variant='outline-warning' onClick={() => openModal()}><i
                                className="far fa-plus"></i> </Button>
                        </Card.Header>

                        <Card.Body className="text-center py-4">
                            <Card.Text onClick={() => openModal()}>
                                <div
                                    className="mb-2"
                                    onClick={() => {/* Add your click handler here */ }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <MdOutlineQrCodeScanner size={65} color="rgba(255, 165, 0, 0.7)" />
                                    <hr />
                                    <div className="text-muted">
                                        Click Here and Scan QR Code to Add New Device
                                    </div>
                                </div>
                            </Card.Text>

                        </Card.Body>
                    </Card>
                </Col>}
            </Row>
            {company && <CreateDeviceModal show={isOpen} handleClose={closeModal} coordinates={{ lat: Number(company.lat), lng: Number(company.lng) }} />}
        </>
    );
};

export default DeviceSettings;
