import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FiX, FiSave, FiTrash } from 'react-icons/fi';
import ConfirmModal from './ConfirmModal';
import UnsavedModal from './Unsaved';

interface ReusableModalProps {
    show: boolean;
    onHide: () => void;
    onSave?: () => Promise<void>;
    title: string;
    children: React.ReactNode;
    deleteMessage?: string;
    deleteAction?: () => Promise<void>;
    hasChange?: boolean;
}

const ReusableModal: React.FC<ReusableModalProps> = ({ show, onHide, onSave, title, children, deleteMessage, deleteAction, hasChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dialog, setDialog] = useState(false);

    const handleSave = async () => {
        if (onSave) {
            setIsLoading(true);
            try {
                await onSave();
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDelete = async () => {
        if (!deleteAction) return;
        setIsLoadingDelete(true);
        await deleteAction();
        setIsLoadingDelete(false);
        setShowDeleteModal(false);
    };

    const checkForChanges = () => {
        if (hasChange) {
            setDialog(true);
        } else {
            onHide();
        }
    };
    return (
        <>
            <Modal show={show} onHide={checkForChanges}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {deleteMessage &&
                        <>
                            <Button
                                variant="outline-danger"
                                className="d-flex align-items-center justify-content-center gap-2 w-100"
                                onClick={() => setShowDeleteModal(true)}
                                disabled={isLoadingDelete}
                            >
                                <FiTrash size={20} />
                                <span>{isLoadingDelete ? 'Deleting...' : 'Delete'}</span>
                            </Button>
                            <hr />
                        </>
                    }
                    {children}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        onClick={checkForChanges}
                        disabled={isLoading}
                        className="d-flex align-items-center gap-2"
                    >
                        <FiX size={24} />
                        <span>Cancel</span>
                    </Button>
                    {onSave && (
                        <Button
                            variant="success"
                            onClick={handleSave}
                            disabled={isLoading}
                            className="d-flex align-items-center gap-2"
                        >
                            <FiSave size={24} />
                            <span>{isLoading ? 'Saving...' : 'Save'}</span>
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            {deleteMessage && <ConfirmModal
                message={deleteMessage || "Are you sure you want to delete this Resource? This action is irreversible and cannot be undone."}
                action={handleDelete}
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                variant="danger"
            />}

            {hasChange && <UnsavedModal
                action={onHide}
                show={dialog}
                handleClose={() => setDialog(false)}
            />}

        </>

    );
};

export default ReusableModal;
