import React, { useEffect } from 'react';
import { Modal, Button, Form, Alert, InputGroup, Card } from 'react-bootstrap';
import { ApiInterfaces } from '../../Interfaces';
import { DynamicForm } from "../Utils/Forms/Form";
import { Section } from "../Utils/Forms/Interface";
import { useRef, useState } from 'react';
import { FormAction } from "../Utils/Forms/Interface";
import { SenquipValidation } from '../Utils/Forms/Validation';
import { GetMqtt } from '../../Server/Api/MqttManager';
import { ReduxActions } from '../../redux/actions';
import { ApiHelper } from '../../Server/Events/ApiOperations';
import { useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { waitForMqttResponse } from '../../Server/Api/MqttHelper';
import ConfirmModal from '../Utils/ConfirmModal';
import { FiTrash, FiKey, FiX, FiSave } from 'react-icons/fi';
import { HelperFunctions } from '../../Helper/functions';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Devices } from './types';
import { FaQrcode } from 'react-icons/fa';
import { QRCodeSVG } from 'qrcode.react';


interface SenquipModalProps {
    show: boolean;
    handleClose: () => void;
    senquip: Partial<ApiInterfaces.Senquip>;
    devices: ApiInterfaces.ListDevices | undefined;
    companies: ApiInterfaces.Company[];
}

interface MqttResponse {
    error?: string;
    type?: string;
    message?: string;
    secret?: string
}

const SenquipModal: React.FC<SenquipModalProps> = ({ show, handleClose, senquip, devices, companies }) => {
    const formRef = useRef<FormAction<Partial<ApiInterfaces.Senquip>> | undefined>(undefined);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { user } = useSelector((state: IRedux.MapState) => state.auth);
    const [secret, setSecret] = useState<string | undefined>();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSecretChangeModal, setShowSecretChangeModal] = useState(false);
    const mqtt = GetMqtt();

    const [selectedDeviceType, setSelectedDeviceType] = useState<string[]>([]);
    const [qrValue, setQrValue] = useState<string>('');

    const deviceTypes = Object.keys(Devices.device).map(key => ({
        id: key,
        label: Devices.device[key].id
    }));
    const qrRef = useRef<HTMLDivElement>(null);

    const handlePrint = () => {
        if (qrRef.current) {
            const printContent = qrRef.current.innerHTML;
            const printWindow = window.open("", "_blank");
            if (printWindow) {
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>Print QR Code</title>
                            <style>
                                body {
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100vh;
                                    background-color: #fff;
                                }
                                .qr-card {
                                    display: inline-block;
                                    padding: 20px;
                                    border: 1px solid #ccc;
                                    border-radius: 10px;
                                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                                }
                            </style>
                        </head>
                        <body>${printContent}</body>
                    </html>
                `);
                printWindow.document.close();
                printWindow.print();
            }
        }
    };
    const DevOtions = HelperFunctions.OptionKeyMapGenerator(devices?.devices || [], "id", "name", false)


    const SenquipForm: Section = {
        type: "section",
        title: "Senquip Form",
        id: "main",
        items: [
            {
                type: "row",
                id: "mainRow",
                items: [
                    { width: 12, type: "text", id: "serial", label: "Senquip Serial", value: "[serial]", disabled: senquip.id ? true : false },
                    { width: 12, type: "text", id: "pin", label: "Senquip Pin", value: "[pin]", disabled: true, hidden: senquip.id ? false : true },
                    { width: 12, type: "dropdown", id: "deviceId", label: "Device", value: "[deviceId]", hidden: senquip.deviceId ? false : true, options: DevOtions, disabled: true },
                    { width: 12, type: "text", id: "device_type", label: "Sensor Type", value: "[device_type]", hidden: senquip.device_type ? false : true, disabled: true },
                    { width: 12, type: "text", id: "companyId", label: "Adopted By CompanyId", value: "[companyId]", hidden: senquip.deviceId ? false : true, disabled: true },
                    // Add more form fields as needed based on your Senquip interface
                ],
            },
        ],
    };

    const handleDelete = async () => {
        mqtt.sendMessage(`senquip/${senquip.serial}/delete`, JSON.stringify({ serial: senquip.serial }));
        const response = await waitForMqttResponse<MqttResponse>(mqtt, `senquip/${senquip.serial}/message`)
        if (response.type === 'success') {
            await ApiHelper.op("senquip.delete", { id: senquip.id || '', managementsId: user.managementsId || '' }, user.managementsId || '')
            console.log(response);
            ReduxActions.ShowSuccess(response.message || 'Senquip Deleted  successfully')
            handleClose()
        }
        else if (response.type === 'error') {
            ReduxActions.ShowError(response.message || 'Error while saving Senquip')
        }
    }

    const handleSave = async () => {
        const f = formRef.current;
        if (!f) return;
        const valid = await f.getYupValidation(SenquipValidation)
        const data = f.getFormData();
        if (data && valid.isValid) {
            try {
                setIsLoading(true);
                mqtt.sendMessage(`senquip/setup`, JSON.stringify(data));
                const response = await waitForMqttResponse<MqttResponse>(mqtt, `senquip/${data.serial}/message`)
                if (response.type === 'success') {
                    const upload = {
                        ...senquip,
                        serial: data.serial,
                        pin: Math.random().toString(36).substring(2, 10).toUpperCase(),
                        timestamp: senquip.timestamp || new Date(),
                        managementsId: user.managementsId || ''
                    } as ApiInterfaces.Senquip

                    if (response.secret) {
                        setSecret(response.secret);
                    }

                    await ApiHelper.op(data.id ? "senquip.edit" : "senquip.create", upload, user.managementsId || '')
                    console.log(response);

                    ReduxActions.ShowSuccess(response.message || 'Senquip Registered  successfully')
                }
                else if (response.type === 'error') {
                    ReduxActions.ShowError(response.message || 'Error while saving Senquip')
                }
                // handleClose();
            } catch (error) {
                console.error('Failed to save Senquip data:', error);
                // Optionally add error handling UI here
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSecretChange = async () => {
        try {
            setIsLoading(true);
            mqtt.sendMessage(`senquip/${senquip.serial}/secret`, JSON.stringify({ serial: senquip.serial }));
            const response = await waitForMqttResponse<MqttResponse>(mqtt, `senquip/${senquip.serial}/message`);

            if (response.type === 'success') {
                if (response.secret) {
                    setSecret(response.secret);
                }
                ReduxActions.ShowSuccess(response.message || 'Secret regenerated successfully');
            } else if (response.type === 'error') {
                ReduxActions.ShowError(response.message || 'Error while regenerating secret');
            }
        } catch (error) {
            ReduxActions.ShowError('Failed to regenerate secret');
        } finally {
            setIsLoading(false);
            setShowSecretChangeModal(false);
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{senquip.id ? 'Edit Senquip' : 'Add Senquip'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {secret && (
                        <>
                            <Alert variant="warning">
                                <Alert.Heading as="h5">Important!</Alert.Heading>
                                <p>This secret key will not be displayed again after closing this modal.</p>
                                <Form.Group className="mb-3">
                                    <Form.Label>Secret Key</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            value={secret}
                                            readOnly
                                            className="font-monospace bg-dark text-light"
                                            onClick={(e) => (e.target as HTMLInputElement).select()}
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => navigator.clipboard.writeText(secret)}
                                        >
                                            Copy
                                        </Button>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        Click to select, or use the copy button
                                    </Form.Text>
                                </Form.Group>
                            </Alert>
                        </>
                    )}
                    <DynamicForm<Partial<ApiInterfaces.Senquip>>
                        actionTriggered={(id, action, form) => {
                            formRef.current = form;
                            return true;
                        }}
                        valueChanged={(id, value, valid, form) => {
                            setHasChanges(true);
                            return true;
                        }}
                        id="Senquip"
                        inputData={senquip}
                        content={[SenquipForm]}
                    />
                    {!senquip.deviceId && senquip.id && (
                        <>
                            <Alert variant="info">
                                <p>Senquip DataLogger Available</p>
                                <p>Awaiting Client Adoption</p>
                            </Alert>
                        </>
                    )}
                    {senquip.id && (
                        <>
                            <hr />
                            <div className="d-flex justify-content-between gap-2">
                                <Button
                                    variant="outline-warning"
                                    className="d-flex align-items-center justify-content-center gap-2"
                                    onClick={() => setShowSecretChangeModal(true)}
                                >
                                    <FiKey size={20} />
                                    <span>Regenerate Secret</span>
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    className="d-flex align-items-center justify-content-center gap-2"
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    <FiTrash size={20} />
                                    <span>Delete Senquip</span>
                                </Button>
                            </div>
                            <hr />
                            {!senquip.deviceId &&
                                <Form.Group className="w-100">
                                    <Form.Label className={selectedDeviceType.length > 0 ? "text-success" : "text-danger"}>
                                        Device Type {selectedDeviceType.length > 0 ? "✓" : "*"}
                                    </Form.Label>
                                    <div className="d-flex gap-2 w-100">
                                        <Typeahead
                                            id="device-type-select"
                                            className="w-75"
                                            options={deviceTypes}
                                            placeholder="Select device type..."
                                            onChange={(selected: any[]) =>
                                                setSelectedDeviceType(selected.map((s) => s.id))
                                            }
                                            selected={selectedDeviceType.map((id) => {
                                                const deviceType = deviceTypes.find((dt) => dt.id === id);
                                                return deviceType
                                                    ? { id: deviceType.id, label: deviceType.label }
                                                    : { id: id, label: id };
                                            })}
                                            isValid={selectedDeviceType.length > 0}
                                            isInvalid={selectedDeviceType.length === 0}
                                        />
                                        <Button
                                            variant="outline-info"
                                            className="d-flex align-items-center gap-2 w-25"
                                            disabled={selectedDeviceType.length === 0}
                                            onClick={() => setQrValue(`se:${senquip.serial};pi:${senquip.pin};t:${selectedDeviceType.join(',')}`)}
                                        >
                                            <FaQrcode size={20} />
                                            <span>QR</span>
                                        </Button>
                                    </div>
                                    {selectedDeviceType.length === 0 && (
                                        <Form.Text className="text-danger">
                                            Please select a device type
                                        </Form.Text>
                                    )}
                                </Form.Group>}
                            {qrValue && (
                                <>
                                    <hr />
                                    <div ref={qrRef} className="d-flex justify-content-center align-items-center mt-3">
                                        <Card className="p-4 qr-card shadow-sm">
                                            <QRCodeSVG value={qrValue} size={256} />
                                            <h5 className="mt-3">SierraNet Sensor QR Code</h5>
                                            <p className="text-muted">Scan to Connect Your Device.</p>
                                        </Card>
                                    </div>
                                    <hr />
                                    <Button
                                        variant="outline-warning"
                                        className="mt-2 w-100"
                                        onClick={handlePrint}
                                    >
                                        Print QR Code
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        disabled={isLoading}
                        className="d-flex align-items-center gap-2"
                    >
                        <FiX size={20} />
                        <span>Cancel</span>
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={isLoading}
                        className="d-flex align-items-center gap-2"
                    >
                        <FiSave size={20} />
                        <span>{isLoading ? 'Saving...' : (senquip.id ? 'Update' : 'Add')}</span>
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                message="Are you sure you want to delete this Senquip? Users won't be able to use it anymore."
                action={handleDelete}
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                variant="danger"
            />
            <ConfirmModal
                title="Regenerate Secret"
                message="Are you sure you want to regenerate the secret? The current secret will become invalid and the devices using it will need to be reconfigured."
                action={handleSecretChange}
                show={showSecretChangeModal}
                handleClose={() => setShowSecretChangeModal(false)}
            />
        </>

    );
};

export default SenquipModal;
