import { useState, useEffect, useCallback } from 'react';

// Define a type for the hook's return value
type UseModalCommunicationReturnType = {
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
};

const useModalCommunication = (modalId: string): UseModalCommunicationReturnType => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Function to open the modal
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    // Function to close the modal
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    // Effect to post message whenever the modal's open state changes

    useEffect(() => {
        if ((window as any).ReactNativeWebView) {
            (window as any).ReactNativeWebView.postMessage(JSON.stringify({
                type: 'MODAL_STATUS',
                modalId,
                isOpen
            }))
        };
    }, [isOpen, modalId]);

    return { isOpen, openModal, closeModal };
};

export default useModalCommunication;